import React, { useContext } from 'react';
import { Form } from '../components';
import { prospectParams } from '../params';
import Context from '../context';

const EditProspect = () => {
    const { state } = useContext(Context);

    function onSubmit(params) {
        api.updateProspect(state.prospect.id, params);
    }

    return(
        <div id="edit-prospect">
            <div id="section-header">
                <div className="section-title">
                    <i className="fa-solid fa-store">
                    </i>
                    &nbsp;
                    Prospect Details</div>
            </div>
            <Form
                type="update_prospect"
                onSubmit={onSubmit}
                submitCopy="Update Prospect"
                inputs={
                    prospectParams({ 
                        prospect: state.prospect,
                        admin: state.user.is_admin,
                    })
                }
            />
        </div>
    )
};

export default EditProspect;