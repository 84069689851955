import React, { useContext, useEffect, useState, useRef } from 'react';
import Context from '../context';
import { Table, Loader, SuccessBox, ErrorBox, Modal } from '../components';
import { parseQuery } from '../utils';

const Reimbursements = ({ location }) => {
    const { api, state } = useContext(Context);
    const [rejectModal, setRejectModal] = useState(null);
    const rejectionFeedback = useRef();

    const tableSchema = {
        name: {
            bold: true,
            textAlign: 'left',
            link: true,
        },
        eligible_date: {
            textAlign: 'left',
            label: 'Elligible Date',
        },
        requested_date: {
            textAlign: 'left',
            label: 'Requested Date',
        },
        limit: {
            textAlign: 'left',
        },
        requested: {
            label: 'Requested',
            textAlign: 'center',
        },
        coverage_level: {
            label: 'Coverage Level',
            textAlign: 'center',
        },
        coverage_upload: {
            label: ' ',
            textAlign: 'right',
            children: ({ datum }) => (
                <a className='btn-table' href={datum.coverage_upload} target="_blank">View</a>
            ),
        },
        accept: {
            label: ' ',
            textAlign: 'right',
            children: ({ datum }) => (
                <a href="#" className='btn-table' onClick={() => api.acceptReimbursement(datum.id)} >Accept</a>
            ),
        },
        decline: {
            label: ' ',
            textAlign: 'right',
            children: ({ datum }) => (
                <a href="#" className='btn-table' onClick={() => setRejectModal(datum)}>Reject</a>
            ),
        },
    };

    useEffect(() => { 
        api.getReimbursements();
    }, []);

    const loading = state.loading.reimbursements;
    const error = state.errors.reimbursements;

    if (loading) return <Loader />;
    if (error) return <ErrorBox error={error} />;

    return(
        <div id="prospects">
            <div id="page-header">
                <div className="page-title">Requests</div>
            </div>
            <SuccessBox success={state.success.update_reimbursement} />
            <ErrorBox error={state.errors.update_reimbursement} />
            {
                state.reimbursements.length === 0

                ?   <div className="card card-success nohover">
                        <div className="card-header-wrapper">
                            <div className="text text-icon">
                                <i className="fa-solid fa-hands-wash icon"></i>
                            </div>
                            <div className="text text-description">You're all caught up on reimbursement requests.</div>
                        </div>
                    </div>

                :    <Table
                        schema={tableSchema}
                        data={state.reimbursements}
                        onLinkClick={(id) => window.location.href = `/employee_users/${id}`}
                    />
            }
            <div id="page-header">
                <div className="page-title">Reports</div>
            </div>
            <Modal
                show={!!rejectModal}
                heading="Reject Reimbursement Request"
                onClose={() => setRejectModal(null)}
            >
                <textarea 
                    placeholder={`Please provide a reason for rejecting ${rejectModal && rejectModal.name}'s reimbursement request:`}
                    style={{
                        width: '100%',
                        margin: '10px 0px',
                        height: '100px',
                        borderRadius: '4px',
                    }}
                    ref={rejectionFeedback}
                />
                <button 
                    className="btn-navy"
                    onClick={() => {
                        setRejectModal(null);
                        api.rejectReimbursement(rejectModal.id, rejectionFeedback.current.value);
                    }}
                >
                    Reject
                </button>
            </Modal>
        </div>
    );
}

export default Reimbursements;