import React, { useContext } from 'react';
import Context from '../context'
import { Form, ErrorBox } from '../components';

const InvitePartnerForm = () => {
	const { api, state } = useContext(Context);
	const isMultiplePlan = state.account.plan_type === 'multiple';

	async function invitePartner(params) {
        await api.invitePartner(params);
    }

	return(
		<div>
			{
				isMultiplePlan

				?	null

				: 	<ErrorBox error="Upgrade to a Multiple Agent Plan to invite other brokers." />
			}
			<Form
	            inputs={[
	                {
	                    label: 'Email',
	                    type: 'text',
	                    key: 'email',
	                },
	                {
	                    label: 'Role',
	                    type: 'select',
	                    key: 'role',
	                    options: ['Partner', 'Admin'],
	                }
	            ]}
	            clearOnSuccess={true}
	            onSubmit={invitePartner}
	            type="invite_partner"
	            disabled={!isMultiplePlan}
	            submitCopy="Invite"
	        />
	    </div>
	);
};

export default InvitePartnerForm;