class Store {
    constructor() {
        this.state = {
            user: {
                first_name: localStorage.getItem('firstName'),
                last_name: localStorage.getItem('lastName'),
                is_admin: localStorage.getItem('isAdmin') === 'true',
                is_partner_admin: localStorage.getItem('isPartnerAdmin') === 'true',
                is_super_admin: localStorage.getItem('isSuperAdmin') === 'true',
                is_company_admin: localStorage.getItem('isCompanyAdmin') === 'true',
                is_employee: localStorage.getItem('isEmployee') === 'true',
            },
            account: {
                name: localStorage.getItem('accountName'),
                has_onboarded: true,
            },
            accounts: [],
            loading: {
                user: true,
            },
            errors: {},
            success: {},
            prospects: [],
            customers: [],
            prospect: {},
            plan: {},
            employee: {},
            design: {},
            classes: [],
            class: null,
            agents: [],
            plans: {},
            employees: [],
            employee_users: [],
            searchResults: {},
            agency: null, // upstream settings
            reimbursements: [],
            issuers: [],
        }

        this.setState = () => {};
    }

    setStateHandler(setState) {
        this.setState = setState;
    }

    reduce(event) {
        console.log(event);

        switch (event.type) {
        case 'login':
            this.state.loading.login = false;
            localStorage.setItem('authToken', event.token);
            localStorage.setItem('tokenExpires', this.tenMinFromNow());
            localStorage.setItem('firstName', event.user.first_name);
            localStorage.setItem('lastName', event.user.last_name);
            localStorage.setItem('accountName', event.user.account.name);
            localStorage.setItem('isAdmin', event.user.is_admin);
            localStorage.setItem('isSuperAdmin', event.user.is_super_admin);
            localStorage.setItem('isPartnerAdmin', event.user.is_partner_admin);
            localStorage.setItem('isCompanyAdmin', event.user.is_company_admin);
            localStorage.setItem('isEmployee', event.user.is_employee);
            this.state.user = event.user;
            break;
        case 'set_user':
            this.state.loading.login = false;
            this.state.loading.user = false;
            this.state.loading.update_user = false;
            this.state.loading.update_password = false;
            this.state.loading.profile_picture = false;
            if (event.loadingType) this.state.loading[event.loadingType] = false;
            localStorage.setItem('firstName', event.user.first_name);
            localStorage.setItem('lastName', event.user.last_name);
            localStorage.setItem('accountName', event.user.account.name);
            localStorage.setItem('isAdmin', event.user.is_admin);
            localStorage.setItem('isSuperAdmin', event.user.is_super_admin);
            this.state.user = { ...this.state.user, ...event.user };
            this.state.account = { ...this.state.account, ...event.user.account };

            if (event.user.plan) this.reduce({
                type: 'set_plan',
                plan: event.user.plan,
            });
            break;
        case 'set_account':
            this.state.loading.onboarding = false;
            this.state.loading.update_account = false;
            if (event.index) {
                const index = this.state.agents.findIndex(({ agency }) => agency.id == event.account.id);
                this.state.agents[index] = { ...this.state.agents[index], agency: event.account };
            } else {
                localStorage.setItem('accountName', event.account.name || 'Your Agency');
                this.state.account = { ...this.state.account, ...event.account };
            };
            break;
        case 'set_accounts':
            this.state.loading.accounts = false;
            this.state.accounts = event.accounts;
            break;
        case 'add_prospect':
            this.state.loading.prospects = false;
            break;
        case 'set_prospects':
            this.state.loading.prospects = false;
            this.state.prospects = event.prospects;
            break;
        case 'set_customers':
            this.state.loading.customers = false;
            this.state.customers = event.customers;
            break;
        case 'set_prospect':
            this.state.loading.company_admin_invite = false;
            this.state.loading.prospects = false;
            this.state.prospect = event.prospect;
            break;
        case 'update_prospect':
            this.state.loading.update_prospect = false;
            this.state.prospect = event.prospect;
            break;
        case 'set_employee':
            this.state.loading.employees = false;
            this.state.loading.employee_invite = false;
            this.state.loading.update_employee = false;
            this.state.employee = event.employee;
            break;
        case 'set_design':
            this.state.loading.designs = false;
            this.state.loading.update_design = false;
            if (event.design.classes) this.state.classes = event.design.classes;
            this.state.design = { ...this.state.design, ...event.design };

        
            // HACK FOR COMPANY ADMIN ONBOARD ROSTER
            if (event.design.employees) 
                this.state.plan.employees = event.design.employees;
            break;
        case 'add_design':
            this.state.loading.designs = false;
            this.state.prospect.designs.push(event.design);
            break;
        case 'set_class':
            this.state.class = this.state.classes.find(klass => event.id == klass.id);
            break;
        case 'set_plans':
            this.state.plans = event.plans;
            break;
        case 'set_agents':
            this.state.agents = this.state.agents.concat(event.agents);
            this.state.loading.agents = false;
            break;
        case 'search_results':
            this.state.searchResults[event.searchType] = event.results;
            break;
        case 'set_plan':
            this.state.errors.update_plan = null;
            this.state.loading.update_plan = false;
            this.state.classes = event.plan.classes;
            this.state.plan = { ...this.state.plan, ...event.plan };
            break;
        case 'set_employees':
            this.state.loading.employees = false;
            this.state.employees = event.employees;
            this.state.employee_users = event.employee_users;
            break;
        case 'update_employee':
            const index = this.state.employees.findIndex(employee => event.employee.id === employee.id);
            this.state.employees[index] = { ...this.state.employees[index], ... event.employee };
            break;
        case 'set_agency':
            this.state.agency = event.agency;
            break;
        case 'set_reimbursements':
            this.state.loading.reimbursements = false;
            this.state.reimbursements = event.reimbursements;
            break;
        case 'set_rates':
            this.state.issuers = event.issuers;
            this.state.plans = event.plans;
            break;
        case 'error':
            this.state.loading[event.errorType] = null;
            this.state.success[event.errorType] = null;
            this.state.errors[event.errorType] = event.error;
            break;
        case 'loading':
            this.state.success[event.loadingType] = null;
            this.state.errors[event.loadingType] = null;
            this.state.loading[event.loadingType] = true;
            break;
        case 'success':
            this.state.errors[event.successType] = null;
            this.state.loading[event.successType] = null;
            this.state.success[event.successType] = event.success;
            break;
        default:
            break;
        };
        this.setState(this.state);
    }
    
    tenMinFromNow() {
        return new Date(new Date().getTime() + 600000);
    }
}

export let store = new Store();
