import { states } from './utils';

export function prospectParams({ prospect=null, admin=false } = {}) {
    return [
        {
            label: 'Prospect Name',
            placeholder: 'Willis Tower',
            icon: 'fa-solid fa-building',
            type: 'text',
            key: 'name',
            defaultValue: prospect && prospect.name,
        },
        {
            label: 'Company Admin Email',
            placeholder: 'john@willistower.com',
            icon: 'fa-solid fa-user-crown',
            info: 'The email address of the prospects administrator. Their email will not be used by 401k Health until they are ready to signup.',
            type: 'email',
            key: 'email',
            defaultValue: prospect && prospect.email,
        },
        {
            label: 'Address',
            placeholder: '223 S. Wacker Dr.',
            icon: 'fa-solid fa-road',
            type: 'text',
            key: 'address',
            defaultValue: prospect && prospect.address,
        },
        {
            label: 'Suite, Building, etc.',
            placeholder: 'Bldg. 1',
            icon: 'fa-solid fa-road',
            type: 'text',
            key: 'address_two',
            defaultValue: prospect && prospect.address_two,
        },
        {
            label: 'City',
            placeholder: 'Chicago',
            icon: 'fa-solid fa-city',
            type: 'text',
            key: 'city',
            defaultValue: prospect && prospect.city,
        },
        {
            label: 'State',
            placeholder: 'State',
            icon: 'fa-solid fa-flag-usa',
            type: 'select',
            key: 'state',
            options: states,
            defaultValue: prospect && prospect.state,
        },
        {
            label: 'Zip Code',
            placeholder: '60606',
            icon: 'fa-solid fa-map-marked',
            type: 'text',
            key: 'zip',
            defaultValue: prospect && prospect.zip,
        },
        {
            label: 'County',
            placeholder: 'Cook',
            type: 'text',
            icon: 'fa-solid fa-map-marker',
            key: 'county',
            tooltip: 'You can do it',
            defaultValue: prospect && prospect.county,
        },
        {
            label: 'Customer',
            type: 'checkbox',
            key: 'customer',
            defaultValue: prospect && prospect.customer,
        }
    ].filter(Boolean);
};


export function employeeParams(employee=null) {
    return [
        {
            label: 'First Name',
            placeholder: 'John',
            icon: 'fa-solid fa-font-case',
            type: 'text',
            key: 'first_name',
            defaultValue: employee && employee.first_name,
        },
        {
            label: 'Last Name',
            placeholder: 'Willis',
            icon: 'fa-solid fa-font-case',
            type: 'text',
            key: 'last_name',
            defaultValue: employee && employee.last_name,
        },
        {
            label: 'Email',
            placeholder: 'john@willistower.com',
            icon: 'fa-solid fa-at',
            type: 'email',
            key: 'email',
            defaultValue: employee && employee.email,
        },
        {
            label: 'Birthdate',
            placeholder: '07-19-1970',
            type: 'date',
            icon: 'fa-solid fa-calendar-day',
            key: 'birthdate',
            defaultValue: employee && employee.birthdate,
        },
        {
            label: 'State',
            placeholder: 'IL',
            icon: 'fa-solid fa-flag-usa',
            type: 'select',
            key: 'state',
            options: states,
            defaultValue: employee && employee.state,
        },
        {
            label: 'Zip Code',
            placeholder: '60606',
            icon: 'fa-solid fa-map-marked',
            type: 'text',
            key: 'zip',
            defaultValue: employee && employee.zip,
        },
        {
            label: 'County',
            placeholder: 'Cook',
            type: 'text',
            icon: 'fa-solid fa-map-marker',
            key: 'county',
            defaultValue: employee && employee.county,
        },
        {
            label: 'Income',
            placeholder: '60,000',
            type: 'number',
            icon: 'fa-solid fa-sack-dollar',
            info: 'The annual income of the employee. His or her W2 form last year will work.',
            key: 'annual_income',
            defaultValue: employee && employee.annual_income,
        },
        {
            label: 'Status',
            type: 'select',
            icon: 'fa-solid fa-hourglass-start',
            options: ['Part-time', 'Full-time', 'Seasonal'],
            key: 'employee_type',
            defaultValue: employee && employee.employee_type,
        },
        {
            label: 'Type',
            type: 'select',
            icon: 'fa-solid fa-money-check',
            options: ['Salary', 'Non-salary'],
            key: 'pay_type',
            defaultValue: employee && employee.pay_type,
        },
        {
            label: 'Coverage Level',
            placeholder: 'Employee Only',
            type: 'select',
            icon: 'fa-solid fa-files-medical',
            info: 'The level of coverage the employee needs.',
            options: ['Employee Only', 'Employee and Family'],
            key: 'coverage_level',
            defaultValue: employee && employee.coverage_level,
        },
        {
            label: 'Spouse',
            type: 'select',
            icon: 'fa-solid fa-rings-wedding',
            info: 'Indicate "Yes" if the spouse is needing coverage.',
            options: ['Yes', 'No'],
            key: 'married',
            defaultValue: employee && employee.married,
        },
        {
            label: 'Spouse Birthdate',
            placeholder: '08-19-1980',
            type: 'date',
            key: 'spouse_birthdate',
            defaultValue: employee && employee.spouse_birthdate,
        },
        {
            label: 'Dependents',
            type: 'number',
            icon: 'fa-solid fa-child',
            info: 'Indicate the number of children that need coverage.',
            key: 'dependents',
            defaultValue: employee && employee.dependents,
        }
    ];
};

export function accountParams(account=null) {
    return [
        {
            label: 'Agency Name',
            type: 'text',
            key: 'name',
            defaultValue: account.name,
        },
        {
            label: 'Address',
            type: 'text',
            key: 'address',
            defaultValue: account.address,
        },
        {
            label: 'Suite',
            type: 'text',
            key: 'suite',
            defaultValue: account.suite,
        },
        {
            label: 'City',
            type: 'text',
            key: 'city',
            defaultValue: account.city,
        },
        {
            label: 'State',
            type: 'select',
            key: 'state',
            options: states,
            defaultValue: account.state,
        },
        {
            label: 'Zip',
            type: 'text',
            key: 'zip',
            defaultValue: account.zip,
        },
    ];
};

export function companyParams(company=null) {
    return [
        {
            label: 'Company Name',
            type: 'text',
            key: 'name',
            defaultValue: company.name,
        },
        {
            label: 'Legal Name',
            type: 'text',
            key: 'legal_name',
            defaultValue: company.legal_name,
        },
        {
            label: 'FEIN',
            type: 'text',
            key: 'fein',
            placeholder: '12-3456789',
            defaultValue: company.fein,
        },
        {
            label: 'Entity Type',
            type: 'select',
            key: 'entity_type',
            options: {
                c_corp: 'C Corporation',
                s_corp: 'S Corporation',
                llc: 'LLC Business Entity',
                gov: 'Government Entity',
                church: 'Church',
                nonprofit: 'Nonprofit Entity',
                psc: 'Professional Service Corporaton',
                partnership: 'Partnership',
                sole_proprietorship: 'Sole Proprietorship'
            },
            defaultValue: company.entity_type,
        },
        {
            label: 'Address',
            type: 'text',
            key: 'address',
            defaultValue: company.address,
        },
        {
            label: 'Suite',
            type: 'text',
            key: 'suite',
            defaultValue: company.suite,
        },
        {
            label: 'City',
            type: 'text',
            key: 'city',
            defaultValue: company.city,
        },
        {
            label: 'State',
            type: 'select',
            key: 'state',
            options: states,
            defaultValue: company.state,
        },
        {
            label: 'Zip',
            type: 'text',
            key: 'zip',
            defaultValue: company.zip,
        },
        {
            label: 'Phone Number',
            type: 'text',
            key: 'phone_number',
            placeholder: '123-456-7890',
            defaultValue: company.phone_number,
        }
    ];
};

export function planParams(plan=null) {
    return [
        {
            label: 'Effective Date',
            key: 'effective_date',
            type: 'date',
            defaultValue: plan.effective_date,
        },
        {
            label: 'End Date',
            key: 'end_date',
            type: 'date',
            defaultValue: plan.end_date,
        },
        {
            label: 'Waiting Period',
            key: 'waiting_period',
            type: 'select',
            options: {
                no_waiting_period: 'No Waiting Period',
                thirty_days: '30 Days',
                sixty_days: '60 Days',
                ninety_days: '90 Days'
            },
            defaultValue: plan.waiting_period,
        },
        {
            label: 'Participation Date',
            key: 'participation_date',
            type: 'select',
            options: {
                month: 'First day of the month following waiting period',
                day: 'Day following the waiting period'
            },
            defaultValue: plan.participation_date,
        },
        {
            label: 'Termination of Participation',
            key: 'termination_of_participation',
            type: 'select',
            multiple: true,
            options: [
                'Termination of Employment',
                'Reduction of Hours',
                'Submits false claims',
                'Transfer to non-eligible employee class',
                'Loss of Medical Plan'
            ],
            defaultValue: plan.termination_of_participation,
        },
        {
            label: 'Termination Date',
            key: 'termination_date',
            type: 'select',
            options: {
                month: 'End of the month in which the termination event occurs',
                day: 'Date in which the termination event occurs'
            },
            defaultValue: plan.termination_date,
        },
        {
            label: 'Carryover of Amounts',
            key: 'carryover',
            type: 'select',
            options: {
                no: 'No Carryover Allowed',
                yes: 'Carryover Allowed'
            },
            defaultValue: plan.carryover,
        },
        {
            label: 'Eligibility of Expenses',
            key: 'eligibility_of_expenses',
            type: 'select',
            options: {
                premiums: 'Premiums Only',
                premiums_and_medical: 'Premiums and Medical Expenses',
                medical: 'Medical Expenses Only'
            },
            defaultValue: plan.eligibility_of_expenses,
        },
        {
           label: 'Legislative Oversight',
           key: 'legislative_oversight',
           type: 'select',
           options: states,
           defaultValue: plan.legislative_oversight,
        },
        {
            label: 'Legal Agent',
            key: 'legal_agent',
            type: 'text',
            defaultValue: plan.legal_agent,
        }
    ];
};
