import React, { useContext, useEffect, useState } from 'react';
import Context from '../context';
import { 
    Loader, 
    ErrorBox, 
    Tabs,
    Roster,
    EditProspect,
    Designs,
} from '../components';
import { parseQuery } from '../utils';

const rosterSchema = {
    name: {
        bold: true,
        textAlign: 'left',
        link: true,
    },
    coverage_level: {
        textAlign: 'left',
        label: 'Coverage Level',
    },
    employee_type: {
        textAlign: 'center',
        label: 'Status',
    },
    pay_type: {
        textAlign: 'right',
        label: 'Type',
    },
};


const Prospect = ({ match, location }) => {
    const { api, state } = useContext(Context);
    const [defaultTab, setDefaulTab] = useState(null);

    useEffect(() => {
        api.getProspect(match.params.id);
        const query = parseQuery(location.search);
        setDefaulTab(query.tab);

    }, []);


    const loading = state.loading.prospects;
    const error = state.errors.prospects;
    
    if (loading) return <Loader />;
    if (error) return <ErrorBox error={error} />;

    const tabs = {
        designs: Designs,
        roster: Roster,
        profile: EditProspect,
    };

    return(
        <div id="prospect">
            <div id="page-header">
                <div className="page-title">{state.prospect.name}
                </div>
                <a
                    className="btn-soft"
                    href="/prospects"
                >
                    <i className="fa-solid fa-caret-left"></i>
                    &nbsp;
                    {"Back"}
                </a>
            </div>
            <div>
                <Tabs 
                    tabs={tabs} 
                    defaultTab={defaultTab} 
                    prospectId={state.prospect.id}
                    employees={state.prospect.employees}
                    schema={rosterSchema}
                />
            </div>
        </div>
    );
}

export default Prospect;