import React, { useEffect, useContext, useState } from 'react';
import Logo from '../assets/logo.png';
import Context from '../context';

const NavBar = ({ location }) => {
    const { api, state } = useContext(Context);
    const [showHam, setShowHam] = useState(false);

    useEffect(() => {
        api.getUser();
    }, []);

    function current(path, match=null) {
        if (path == '/') {
            return location.pathname ==  path;
        } else {
            return location.pathname.includes(match || path);
        }
    }

    function fullName() {
        if (state.user)
            return (state.user.first_name || '') + ' ' + (state.user.last_name || '');
    }

    function accountName() {
        if (state.account)
            return state.account.name || 'Your Agency';
    }

    function navlinks() {
        if (state.user.is_company_admin) {
            return(
                <div className="navlinks">
                    <a href="/" className={current("/") ? "nav-current" : ""}>
                        Dashboard
                    </a>
                    <a href="/reimbursements" className={current("/reimbursements") ? "nav-current" : ""}>
                        Reimbursements
                    </a>
                    <a href="/roster" className={current("/roster") ? "nav-current" : ""}>
                        Roster
                    </a>
                    <a href="/docs" className={current("/docs") ? "nav-current" : ""}>
                        Docs
                    </a>
                </div>
            );
        } else if (state.user.is_employee) {
            return(
                <div className="navlinks">
                    <a href="/" className={current("/") ? "nav-current" : ""}>
                        Dashboard
                    </a>
                    <a href="/reimbursement" className={current("/reimbursement") ? "nav-current" : ""}>
                        Reimbursement
                    </a>
                    <a href="/health_insurance" className={current("/health_insurance") ? "nav-current" : ""}>
                        Health Insurance
                    </a>
                    <a href="/benefits" className={current("/benefits") ? "nav-current" : ""}>
                        Benefits
                    </a>
                    <a href="/docs" className={current("/docs") ? "nav-current" : ""}>
                        Docs
                    </a>
                </div>
            );
        } else {
            return(
                <div className="navlinks">
                    <a href="/" className={current("/") ? "nav-current" : ""}>
                        Dashboard
                    </a>
                    <a href="/prospects" className={current("/prospects", "/prospect") ? "nav-current" : ""}>
                        Prospects
                    </a>
                    <a href="/customers" className={current("/customers") ? "nav-current" : ""}>
                        Customers
                    </a>
                </div>
            );
        }
    };

    function mobileOnlyLinks() {
        if (state.user.is_company_admin) {
            return(
                <div>
                    <a href="/" className="mobile-only-link" >Home</a>
                    <a href="/reimbursements" className="mobile-only-link" >Reimbursements</a>
                    <a href="/roster" className="mobile-only-link" >Roster</a>
                    <a href="/docs" className="mobile-only-link" >Docs</a>
                </div>
            );
        } else if (state.user.is_employee) {
            return(
                <div>
                    <a href="/" className="mobile-only-link" >Home</a>
                    <a href="/reimbursement" className="mobile-only-link" >Reimbursement</a>
                    <a href="/health_insurance" className="mobile-only-link" >Health Insurance</a>
                    <a href="/benefits" className="mobile-only-link" >Benefits</a>
                    <a href="/docs" className="mobile-only-link" >Docs</a>
                </div>
            );
        } else {
            return(
                <div>
                    <a href="/" className="mobile-only-link" >Home</a>
                    <a href="/prospects" className="mobile-only-link" >Prospects</a>
                </div>
            );
        }
    }



    return(
        <div className="nav-bg">
            <div className="nav">
                <div className="nav-logo">
                    <a href="/" >
                        <img 
                            className="nav-logo"
                            src={Logo} 
                            alt="401khealth logo" 
                        />
                    </a>
                </div>
                {navlinks()}
                <div className="nav-profile">
                    <div className="nav-profile-details" >
                        <div className="nav-name" style={{marginTop: '6px'}}>
                            {fullName()}
                        </div>
                    </div>
                    {
                        state.user.profile_picture_url

                        ?   <img 
                                src={state.user.profile_picture_url} 
                                className="nav-avatar" 
                                onClick={() => setShowHam(prev => !prev)}
                            />

                        :   <i 
                                className="fa-solid fa-user" 
                                id="default-avatar" 
                                onClick={() => setShowHam(prev => !prev)}
                            />
                    }
                    <div 
                        className="hamburger-menu"
                        style={{
                            display: showHam ? "" : "none",
                        }}
                    >
                        {mobileOnlyLinks()}
                        {
                            state.user.is_super_admin

                                ?   <a href="/agents" >
                                    <i className="fa-solid fa-users"></i>
                                    &nbsp;&nbsp;
                                    Brokers</a>

                                :   null

                        }
                        {
                            state.user.is_super_admin

                                ?   <a href="/agencies" >
                                    <i className="fa-solid fa-building"></i>
                                    &nbsp;&nbsp;
                                    Agencies</a>

                                :   null

                        }
                        {
                            state.user.is_company_admin

                                ?   <a href="/settings" >
                                        <i className="fa-solid fa-cog"></i>
                                        &nbsp;&nbsp;
                                        Settings
                                    </a>

                                :   null

                        }
                        {
                            state.user.is_company_admin

                                ?   <a href="/company" >
                                        <i className="fa-solid fa-building"></i>
                                        &nbsp;&nbsp;
                                        Company
                                    </a>

                                :   null

                        }
                        <a href="/profile" >
                            <i className="fa-solid fa-user-tie"></i>
                            &nbsp;&nbsp;
                            Profile
                        </a>
                        <a onClick={() => {
                            localStorage.clear();
                            window.location.href = "/login";
                        }}>
                            <i className="fa-solid fa-door-open"></i>
                            &nbsp;&nbsp;
                            Sign Out</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NavBar;