import React, { useContext, useState } from 'react';
import { PasswordConditions, Form } from '../components';
import Context from '../context';

const SignUp = ({ match }) => {
    
    const { token } = match.params;
    const { api } = useContext(Context);

    const [password, setPassword] = useState('');
    const [passwordCopy, setPasswordCopy] = useState('');

    function onSubmit(params) {        
        if (PasswordConditions.allConditionsPass(password, passwordCopy)) {
            params.password = password;
            api.createUser(params);
        }
    }

    return(
        <div className="auth-container" >
            <div className="auth-wrapper">
                <div className="auth-header">
                    <div className="text text-heading">Welcome to <b>401k</b>health</div>
                    <div className="text text-sub">Let's get you started!</div>
                </div>
            </div>
            <Form
                onSubmit={onSubmit}
                submitCopy="Create Account"
                type="login"
                col="12"
                inputs={[
                    {
                        label: 'First Name',
                        type: 'text',
                        key: 'first_name',
                    },
                    {
                        label: 'Last Name',
                        type: 'text',
                        key: 'last_name',
                    },
                    {
                        label: 'Email',
                        type: 'email',
                        key: 'email',
                    },
                    (
                        token

                        ?   {
                                type: 'hidden',
                                key: 'token',
                                defaultValue: token,

                            }

                        :   null
                    )
                ].filter(Boolean)}
            >
                <div className="col-md-12">
                    <div className="input-primary">
                        <label>Password</label>
                        <input 
                            value={password}
                            onChange={({ target }) => setPassword(target.value)} 
                            type="password"
                        />
                    </div>
                    <div className="input-primary">
                        <label>Verify Password</label>
                        <input 
                            value={passwordCopy}  
                            onChange={({ target }) => setPasswordCopy(target.value)} 
                            type="password"
                        />
                    </div>
                    <PasswordConditions password={password} passwordCopy={passwordCopy} />
                </div>
            </Form>
            <div className="auth-footer">
                <div className="text text-body">
                    By submitting this form, you agree to 401k Health's <b>Terms of Use</b> and <b>Privacy Policy</b>.
                </div>
                <br/>
                <div className="text text-potential">
                    Already have an account? &nbsp;&nbsp;<a href="../pages/login"><b>Sign In</b></a>
                </div>
            </div>
        </div>
    )
}

export default SignUp;