import React, { useContext } from 'react';
import {
  Dashboard,
  Prospects,
  NewProspect,
  Prospect,
  NewEmployee,
  EditEmployee,
  Design,
  Profile,
  Account,
  Company,
  Agents,
  Agencies,
  DesignReview,
  Subscribe,
  Payouts,
  Reimbursements,
  HealthInsurance,
  Benefits,
  Settings,
  Docs,
  Roster,
  Enroll,
  NewReimbursement,
  Reimbursement,
  Customers,
  _404_,
} from "./pages";
import { Route, Switch } from 'react-router-dom';
import { PrivateRoute, NavBar, ErrorBox, SuccessBox } from './components';
import Context from './context';

const Routes = () => {
    const { state } = useContext(Context);

    return(
      <div>
          <Route exact component={NavBar} />
          <div className="container">

              <ErrorBox error={state.errors.standard} />
              <SuccessBox success={state.success.standard} />
              <Switch>
                <PrivateRoute path="/" exact component={Dashboard} />
                <PrivateRoute path="/customers" exact component={Customers} />
                <PrivateRoute path="/prospects" exact component={Prospects} />
                <PrivateRoute path="/prospects/new" exact component={NewProspect} />
                <PrivateRoute path="/prospects/:id" exact component={Prospect} />
                <PrivateRoute path="/prospects/:prospect_id/employees/new" exact component={NewEmployee} />
                <PrivateRoute path="/employees/:id" exact component={EditEmployee} />
                <PrivateRoute path="/prospects/designs/:id" exact component={Design} />
                <PrivateRoute path="/profile" exact component={Profile} />
                <PrivateRoute path="/agency" exact component={Account} />
                <PrivateRoute path="/company" exact component={Company} />
                <PrivateRoute path="/agents" exact component={Agents} />
                <PrivateRoute path="/agencies" exact component={Agencies} />
                <PrivateRoute path="/prospects/designs/:id/review" exact component={DesignReview} />
                <PrivateRoute path="/subscribe" exact component={Subscribe} />
                <PrivateRoute path="/payouts" exact component={Payouts} />
                <PrivateRoute path="/reimbursements" exact component={Reimbursements} />
                <PrivateRoute path="/health_insurance" exact component={HealthInsurance} />
                <PrivateRoute path="/benefits" exact component={Benefits} />
                <PrivateRoute path="/docs" exact component={Docs} />
                <PrivateRoute path="/settings" exact component={Settings} />
                <PrivateRoute path="/roster" exact component={Roster} />
                <PrivateRoute path="/enroll" exact component={Enroll} />
                <PrivateRoute path="/reimbursement/new" exact component={NewReimbursement} />
                <PrivateRoute path="/reimbursement" exact component={Reimbursement} />
                <Route component={_404_} />
              </Switch>
          </div>
      </div>
    )
}

export default Routes;