import React, { useContext } from 'react';
import { Form, ErrorBox, AgentTable, PaymentPlan, InvitePartnerForm, Loader } from '../components';
import Context from '../context';
import { companyParams } from '../params';

const Company = () => {
    const { api, state } = useContext(Context);

    function onSubmit(params) {
        api.updateAccount(state.account.id, params);
    } 

    const loading = state.loading.user;

    if (loading) return <Loader />;

    return(
        <div>
            <div id="page-header">
                <div className="page-title">{state.account.name}</div>
            </div>
            <div id="section-header">
                <div className="section-title">
                    <i className="fa-solid fa-briefcase"></i>
                    &nbsp;
                    Company Details</div>
            </div>
            <Form
                onSubmit={onSubmit}
                submitCopy="Save Changes"
                type="update_account"
                inputs={companyParams(state.account)}
            />
            <PaymentPlan planType={false} />
        </div>
    );
}

export default Company;