import React from 'react';

const ErrorBox = ({ error }) => {
    if (error) return(
        <div className="card card-error nohover">
            <div className="card-header-wrapper">
                <div className="text text-icon">
                    <i className="fa-solid fa-exclamation-triangle icon-light-navy icon"></i>
                </div>
                {error}
            </div>
        </div>
    );

    return <div></div>;
}

export default ErrorBox;