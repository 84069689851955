import React, { useContext } from 'react';
import Context from '../context';
import { Table, UpstreamSettings } from '.';

const AgentTable = ({ agents=[], link=false, fetchMore, onDestroy, destroyCondition }) => {
    const { api, state } = useContext(Context);

    function setAgency(agency) {
        return () => api.store.reduce({
            type: 'set_agency',
            agency,
        });
    }

    async function toggleSuperAdmin(agent) {
        const res = await api.updateAgent(agent.id, { type: agent.type == 'SlyngAdmin' ? 'PartnerAdmin' : 'SlyngAdmin' });

        if (res) {
            window.location.reload(); 
        }
    }

    const tableSchema = {
        name: {
            bold: true,
            textAlign: 'left',
            link: true,
        },
        email: {
            textAlign: 'left',
        },
        prospects: {
            textAlign: 'right',
        },
        role: {
            textAlign: 'right',
        },
        super_admin: {
            label:  ' ',
            textAlign: 'right',
            children: ({ datum }) => {
                if (datum.type == 'SlyngAdmin') {
                    return(
                        <div
                            onClick={() => toggleSuperAdmin(datum)}
                            style={{ fontWeight: 'bold',cursor: 'pointer', color:'#e1795a'}}
                        >
                            Remove Super Admin
                        </div>
                    )
                } else if (datum.type == 'AgencyAdmin') {
                    return(
                        <div
                            onClick={() => toggleSuperAdmin(datum)}
                            style={{ fontWeight: 'bold',cursor: 'pointer', color:'#e1795a'}}
                        >
                            Make Super Admin
                        </div>
                    )
                } else {
                    return(
                        <div
                            onClick={() => toggleSuperAdmin(datum)}
                            style={{ fontWeight: 'bold',cursor: 'pointer', color:'#e1795a'}}
                        >
                            Make Super Admin
                        </div>
                    )
                }
            }
        },
        exempt_from_payment: {
            textAlign: 'right',
            label: 'Exmempt from Payment?',
            children: ({ datum }) => <i className={`fa-solid fa-${datum.agency.exempt_from_payment ? 'check' : 'times'}`} />
        },
        upstream_settings: {
            label: 'Settings',
            textAlign: 'right',
            children: ({ datum }) => <i className="fa-solid fa-cog" onClick={setAgency(datum.agency)} />
        }
    };

    return(
        <div>
            <Table
                schema={tableSchema}
                data={agents}
                onLinkClick={(id) => link ? window.location.href = `/prospects?agent_id=${id}` : null}
                fetchMore={fetchMore}
                onDestroy={onDestroy}
                destroyCondition={destroyCondition}
            />
            <UpstreamSettings 
                {...state.agency}
                show={!!state.agency}
                onClose={setAgency(null)} 
            />
        </div>
    );

}

export default AgentTable;