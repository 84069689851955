import React, { useContext, useEffect, useState, useRef } from 'react';
import Context from '../context';
import { 
    Loader, 
    ErrorBox,
    SuccessBox, 
    Roster, 
    Classes, 
    CostCard,
    PricingBreakdown,
    GenerateProposal,
} from '../components';

const Design = ({ match }) => {
    const { api, state } = useContext(Context);
    const [nameInput, setNameInput] = useState(false);
    const [bulkActivate, setBulkActivate] = useState(true);
    const designName = useRef();

    function doNothing(e) {
        e.stopPropagation();
    }

    function activate(e, id) {
        e.stopPropagation();
        api.activateEmployee(state.design.id, id);
    }

    function deactivate(e, id) {
        e.stopPropagation();
        api.deactivateEmployee(state.design.id, id);
    }

    function updateDesignName(e) {
        e.preventDefault();
        api.updateDesignName(state.design.id, designName.current.value);
        setNameInput(false);
    }

    function bulk() {
        if (bulkActivate) {
            api.bulkActivate(state.design.id);
            setBulkActivate(false);
        } else {
            api.bulkDeactivate(state.design.id);
            setBulkActivate(true);
        };
    }

    const rosterSchema = {
        name: {
            textAlign: 'left',
            link: true,
            bold: true,
            defaultSort: true,
        },
        class: {
            label: 'Employee Class',
            textAlign: 'left',
        },
        level: {
            label: 'Coverage Level',
            textAlign: 'left',
        },
        dependents: {
            label: 'Covered Dependents',
            textAlign: 'left',
        },
        active: {
            label: 'Status',
            width: '10px',
            textAlign: 'center',
            permaSort: true,
            onClick: bulk,
            children: ({ datum }) => (
                <div>
                    {
                        !datum.class

                        ?   <span className="fa-solid fa-ban edm-unmatched icon-red icon" onClick={doNothing} ></span>

                        :   datum.active

                            ?   <span className="fa-solid fa-check-circle edm-matched-active icon-green icon" onClick={(e) => deactivate(e, datum.id) } ></span>

                            :   <span className="fa-solid fa-times-circle edm-matched-inactive icon-yellow icon" onClick={(e) => activate(e, datum.id) } ></span>
                    }
                </div>
            ),
        },
        cost: {
            label: 'Cost',
            info: 'The cost of the Target Plan',
            icon: 'fa-solid fa-bullseye-arrow icon',
            textAlign: 'right',
        },
        limit: {
            label: 'Limit',
            info: 'The total reimbursement amount available to the employee each month',
            icon: 'fa-solid fa-envelope-open-dollar icon-green icon',
            textAlign: 'right',
        },
    };

    useEffect(() => {
        api.getDesign(match.params.id);
    }, []);

    const loading = state.loading.designs;
    const error = state.errors.designs;
    const success = state.success.designs;

    if (loading) return <Loader />;
    if (error) return <ErrorBox error={error} />;

    const { 
        id, name, prospect_name, prospect_id, reimbursements, reimbursement_percentage, employee_count,
        employer_monthly_cost, employer_annual_cost, employees, active_employee_count,
        employee_monthly_cost, employee_annual_cost, total_monthly_cost, total_annual_cost,
    } = state.design;

    return(
        <div id="prospects">
            <div id="page-header">
                <div className="page-title">{prospect_name}</div>
                <a
                    className="btn-soft"
                    href={`/prospects/${prospect_id}?tab=designs`}
                >
                    <i className="fa-solid fa-caret-left"></i>
                    &nbsp;
                    {"Back"}
                </a>
            </div>
            <SuccessBox success={success} />
            <div id="section" style={{borderBottom: '1px solid #e1e1e1', paddingBottom: '5px'}}>
                {
                    nameInput

                    ?   <form onSubmit={updateDesignName} >
                            <div className="input-primary" style={{maxWidth: '200px'}} >
                                <input 
                                    className="design-name"
                                    type="text" 
                                    ref={designName}
                                    onBlur={updateDesignName}
                                    defaultValue={name}
                                />
                            </div>
                        </form>

                    :   <div className="design-name-wrapper" >
                            <div>
                                <div 
                                    className="design-name"
                                    onDoubleClick={() => setNameInput(true)}
                                >
                                    {name}
                                </div>
                                <i 
                                    className="fa-solid fa-pencil-alt icon-navy icon" 
                                    id="edit-design-name"
                                    onClick={() => setNameInput(true)}
                                />
                            </div>
                        </div>

                }
                <div className="card-wrapper">
                    <div className="text text-description">
                        <i className="fa-solid fa-bullhorn icon-navy"></i>
                        &nbsp;&nbsp;
                        Summary: <b>{prospect_name}</b> will reimburse up to <b>{reimbursement_percentage}</b> of the employees' cost of the <b>Target Plan</b>.
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <GenerateProposal 
                            copy="Download"
                            id={state.design.id} 
                            name={name}
                            disabled={state.classes.length == 0 || employees.length == 0}
                        />
                        <a 
                            className="btn-green" 
                            href={`/prospects/designs/${id}/review`}
                            style={{display:'none'}}
                        >
                            Review Design
                        </a>
                    </div>
                </div>
            </div>
            <PricingBreakdown 
                reimbursements={reimbursements}
            />
            <div className="row">
                <div className="card-wrapper col-lg-3 col-md-6 col-sm-12 nohover">
                    <CostCard
                        value={active_employee_count}
                        altValue={`${employee_count} total on roster`}
                        textColor="#676767"
                        icon="fa-solid fa-user-astronaut icon-grey"
                        label="Participants"
                    />
                </div>
                <div className="card-wrapper col-lg-3 col-md-6 col-sm-12 nohover">
                    <CostCard
                        value={employee_annual_cost}
                        altValue={`${employee_monthly_cost} /mo.`}
                        textColor="#676767"
                        icon="fa-solid fa-money-bill-wave icon-grey"
                        info="The amount the employees will collectively pay for the Target Plan."
                        label="Employees Pay"
                    />
                </div>
                <div className="card-wrapper col-lg-3 col-md-6 col-sm-12 nohover">
                    <CostCard
                        value={employer_annual_cost}
                        altValue={`${employer_monthly_cost} /mo.`}
                        textColor="#676767"
                        icon="fa-solid fa-envelope-open-dollar icon-grey"
                        info="The amount the employer will pay in reimbursements and fees."
                        label="Employer Pays"
                    />
                </div>
                <div className="card-wrapper col-lg-3 col-md-6 col-sm-12 nohover">
                    <CostCard
                        value={total_annual_cost}
                        altValue={`${total_monthly_cost} /mo.`}
                        icon="fa-solid fa-sack-dollar icon-navy"
                        info="The total cost of the plan, including both the employer and collective employee portion."
                        label="Total Cost"
                    />
                </div>
            </div>
            <Classes />
            <Roster
                prospectId={prospect_id}
                employees={employees}
                schema={rosterSchema}
            >
            </Roster>
        </div>
    );
}

export default Design;