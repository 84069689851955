import React, { useContext } from 'react';
import { Form, ErrorBox, AgentTable, PaymentPlan,  Loader, InvitePartnerForm } from '../components';
import Context from '../context';
import { accountParams } from '../params';

const Account = () => {
    const { api, state } = useContext(Context);

    function onSubmit(params) {
        api.updateAccount(state.account.id, params);
    } 

    if (state.loading.user) return <Loader />;
    
    return(
        <div>
            <div id="page-header">
                <div className="page-title">{state.account.name}</div>
            </div>
            <div id="section-header">
                <div className="section-title">
                    <i className="fa-solid fa-briefcase"></i>
                    &nbsp;
                    Agency Details</div>
            </div>
            <Form
                onSubmit={onSubmit}
                submitCopy="Save Changes"
                type="update_account"
                inputs={accountParams(state.account)}
            />
            {/* <div id="section-header">
                <div className="section-title">
                    <i className="fa-solid fa-users"></i>
                    &nbsp;
                    Brokers</div>
            </div>
            <ErrorBox error={state.errors.users} />
            <AgentTable 
                agents={state.account.partners} 
                destroyCondition={(data) => data.role != 'Admin'}
                onDestroy={(id) => api.destroyUser(id)}
            />
             <div id="section-header">
                <div className="section-title">
                    <i className="fa-solid fa-user-plus"></i>
                    &nbsp;Invite Broker</div>
            </div>
            <InvitePartnerForm /> */}
            <PaymentPlan />
        </div>
    );
}

export default Account;