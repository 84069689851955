import React, { useContext } from 'react';
import Context from '../context';

const Enroll = () => {
 	const { api, state } = useContext(Context);

	async function updateAndRedirect() {
		const res = await api.updateUser(state.user.id, { has_coverage: true });
		if (res) window.location.href = '/reimbursement/new';
	};

	return(
		<div id="dashboard">
            <div id="page-header">
                <div className="page-title">Enroll in Coverage</div>
            </div>
			<div className="text text-sub">
            	<p>
					You'll need to enroll in a qualified health plan in order to participate in your employers Individual Coverage HRA plan. Use our online tool for fast and easy enrollment. 
				</p><br/>
				<p>
				You're eligible (or Your family is eligible) to be reimbursed up to <b><u>($900)</u></b> per month for coverage starting (March 1, 2021) or later.
				</p><br/>
			</div>
			<div className="enroll-box" >
				<div>
					<h3>Enroll Online</h3>
					<div>We make it easy to pick your own plan online and enroll.</div>
				</div>
				<a className="btn-task" href="https://www.healthsherpa.com/?_agent_id=401khealth" target="_blank" >Enroll →</a>
			</div>
			<div className="btn-task-outline" style={{marginRight: '20px'}} onClick={updateAndRedirect}>
				I have coverage
			</div>
        </div>
	)
};

export default Enroll;