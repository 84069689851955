import React, { useContext, useState } from 'react';
import { OnboardFlow, Form, PaymentPlan, InvitePartnerForm } from '.';
import { accountParams } from '../params';
import Context from '../context';

const AgencyAdminOnboard = () => {
	const { state, api } = useContext(Context);
    const [step, setStep] = useState(state.account.onboard_step);

	async function updateAccount(params) {
        const res = await api.updateAccount(state.account.id, params);
        if (res) setStep(2);
    } 

	const steps = [
		{
			header: 'Complete Registration',
			description: "Please enter your credit card details to start your subscription plan. The plan is $185 per month.",
			childComponent: (
				<PaymentPlan allowCoupon={true} />
			),
		},
	];


	return(
		<div id="dashboard">
		    <OnboardFlow
		    	defaultStep={step}
		    	steps={steps}
		   	/>
		</div>
	)
};

export default AgencyAdminOnboard;