import React, { useContext } from 'react';
import Context from '../context';
import { Form, PaymentMethodCard, ErrorBox, SuccessBox, BtnSpinner, Loader } from '../components';

const PaymentPlan = ({ onUpdate, planType=true, allowCoupon=false }) => {
    const { state, api } = useContext(Context);

    const success = state.success.plans;
    const loading = state.loading.plans;
    const error = state.errors.plans;

    const account = state.account;
    const subscribed = account.subscribed;

    async function handlePaymentSource(id) {
        const res = await api.attachPaymentMethod(account.id, id);
        if (res) onUpdate(id);
    }

    function updatePaymentPlan(plan) {
        return function() {
            if (window.confirm('Are you sure you want to update your 401k Health Subscription?')) {
                api.updatePaymentPlan(account.id, plan);
            };
        }
    }

    if (state.loading.user) return <Loader />;

    return(
        <div>
            <ErrorBox error={error} />
            <SuccessBox success={success} />
            {
                allowCoupon

                &&  <div>
                        <div id="section-header">
                            <div className="section-title">
                                <i className="fa-solid fa-sliders-h"/>
                                &nbsp;
                                Apply Coupon
                            </div>
                        </div>
                        <Form
                            type="coupons"
                            col="12"
                            onSubmit={params => api.applyCoupon(account.id, params)}
                            submitCopy="Apply"
                            inputs={[
                                {
                                    key: 'coupon',
                                    type: 'text',
                                    placeholder: 'Coupon Code',
                                    defaultValue: account.coupon,
                                },
                            ]}
                        />
                    </div>
            }
            {/* {
                planType

                ?   <div>
                        <div id="section-header">
                            <div className="section-title">
                                <i className="fa-solid fa-sliders-h"/>
                                &nbsp;
                                Subsciption Type
                            </div>
                        </div>
                        <div className="flex">  
                            <div 
                                className={`payment-plan-tab ${account.plan_type == 'single' ? 'current-payment-plan' : ''}`} 
                                onClick={updatePaymentPlan('single')} 
                            >
                                <div className="pp-name">Single Agent</div>
                                <div className="pp-price">$99.99</div>
                            </div>
                            <div 
                                className={`payment-plan-tab ${account.plan_type == 'multiple' ? 'current-payment-plan' : ''}`} 
                                onClick={updatePaymentPlan('multiple')}
                            >
                                <div className="pp-name">Multiple Agents</div>
                                <div className="pp-price">$399.99</div>
                            </div>
                        </div>
                    </div>

                :   null
            } */}
            {/*<div className="text-right">
                {
                    loading

                    ?   <BtnSpinner />

                    :   subscribed

                        ?   <div className="btn-soft" onClick={cancelSubscription} >
                                Cancel Plan
                            </div>

                        :   null
                }
            </div>*/}
            <PaymentMethodCard
                {...state.account.payment_method}
                onUpdate={handlePaymentSource} 
            />            
        </div>
    );
};

export default PaymentPlan;