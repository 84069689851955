import React, { useContext, useState, useEffect } from 'react';
import { PasswordConditions, Form, ErrorBox, Loader } from '../components';
import Context from '../context';
import Logo from '../assets/logo.png';

const EmployeeSignUp = ({ match }) => {
    
    const { token } = match.params;
    const { api, state } = useContext(Context);

    const [password, setPassword] = useState('');
    const [passwordCopy, setPasswordCopy] = useState('');

    useEffect(() => {
        api.getEmployeeInvite(token);
    }, []);


    function onSubmit(params) {        
        if (PasswordConditions.allConditionsPass(password, passwordCopy)) {
            params.password = password;
            api.createEmployeeUser(params)
        }
    }

    const error = state.errors.employee_invite;
    const loading = state.loading.employee_invite;


    if (error) return(
         <div className="auth-container" >
            <img 
                className="auth-logo"
                src={Logo} 
                alt="401khealth logo" 
            />
            <ErrorBox error={error} />
        </div>
    );

    if (loading) return(
         <div className="auth-container" >
            <img 
                className="auth-logo"
                src={Logo} 
                alt="401khealth logo" 
            />
            <Loader />
        </div>
    );

    const { employee } = state;

    return(
        <div className="auth-container" >
            <div className="auth-wrapper">
                <div className="auth-header">
                    <div className="text text-heading">Welcome to 401khealth</div>
                    <div className="text text-sub">Let's get you started</div>
                </div>
                <img 
                    className="auth-logo"
                    src={Logo} 
                    alt="401khealth logo" 
                />
            </div>
            <div className="card card-welcome nohover">
                <div className="text text-body">
                    Getting started is simple. First, let's <b>create your account</b>, then you'll be able to [some other stuff].
                </div>
            </div>
            <Form
                onSubmit={onSubmit}
                submitCopy="Sign Up"
                type="employees"
                col="12"
                inputs={[
                    {   
                        label: 'First Name',
                        key: 'first_name',
                        type: 'text',
                        defaultValue: employee.first_name,
                    },
                    {   
                        label: 'Last Name',
                        key: 'last_name',
                        type: 'text',
                        defaultValue: employee.last_name,
                    },
                    {
                        label: 'Email',
                        type: 'email',
                        key: 'email',
                        defaultValue: employee.email,
                    },
                    {
                        type: 'hidden',
                        key: 'token',
                        defaultValue: token,

                    }

                ]}
            >
                <div className="col-md-12">
                    <div className="input-primary">
                        <label>New Password</label>
                        <input 
                            value={password}
                            onChange={({ target }) => setPassword(target.value)} 
                            type="password"
                        />
                    </div>
                    <div className="input-primary">
                        <label>Verify Password</label>
                        <input 
                            value={passwordCopy}
                            onChange={({ target }) => setPasswordCopy(target.value)} 
                            type="password"
                        />
                    </div>
                    <PasswordConditions password={password} passwordCopy={passwordCopy} />
                </div>
            </Form>
        </div>
    )
}

export default EmployeeSignUp;
