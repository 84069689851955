import React, { useState } from 'react';
import PaymentMethodForm from './payment_method_form';

const PaymentMethodCard = ({ id, brand, last4, onUpdate, token=false }) => {
    const [edit, setEdit] = useState(false);

    if (edit || !id) return(
        <div>
            <div id="section-header">
                <div className="section-title">
                    <i className="fa-solid fa-credit-card-front"/>
                    &nbsp;
                    Payment Method
                </div>
            </div>
            <div className="card">
                <PaymentMethodForm onSubmit={onUpdate} token={token} />
            </div>
        </div>
    );

    return(
        <div>
            <div id="section-header">
                <div className="section-title">
                    <i className="fa-solid fa-dollar"/>
                    &nbsp;
                    Payment Method
                </div>
            </div>
            <div className="card">
                <div className="card-wrapper">
                    <div className="b-copy">
                        <div>{brand}</div>
                        <div>{last4}</div>
                    </div>
                    <i 
                        className="fa-solid fa-pencil-alt icon-navy icon" 
                        onClick={() => setEdit(true)} 
                    />
                </div>
            </div>
        </div>
    );
};

export default PaymentMethodCard;