import React, { useContext, useState } from 'react';
import Context from '../context';
import { download } from '../utils';
import { BtnSpinner } from '../components';

const Docs = () => {
	const { api, state } = useContext(Context);
	const [pd, setPd] = useState(false);
	const [aa, setAa] = useState(false);

	async function downloadPlanDocument() {
		setPd(true);
		const url = await api.downloadPlanDocument(state.plan.id);
		setPd(false);
		download(url, `${state.account.name} Plan Document.pdf`)
	}

	async function downloadAdoptionAgreement() {
		setAa(true);
		const url = await api.downloadAdoptionAgreement(state.plan.id);
		setAa(false);
		download(url, `${state.account.name} Adoption Agreement.pdf`)
	}

	return(
		<div id="docs">
			<div id="page-header">
				<div className="page-title">Documents</div>
			</div>
			{
				pd

				? 	<BtnSpinner />

				: 	<button 
						className="btn-orange" 
						onClick={downloadPlanDocument} 
					>
						Download Plan Document
					</button>
			}
			<br/>
			{
				aa

				? 	<BtnSpinner />

				: 	<button 
						className="btn-orange" 
						onClick={downloadAdoptionAgreement} 
					>
						Download Adoption Agreement
					</button>
			}
		</div>
	);
};

export default Docs;