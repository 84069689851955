import React, { useEffect, useContext } from 'react';
import { Table, Loader, ErrorBox, SuccessBox } from '../components';
import Context from '../context';

const Roster = () => {
	const { api, state } = useContext(Context);

    const rosterSchema = {
        name: {
            textAlign: 'left',
            link: true,
        },
        email: {
            textAlign: 'left',
            link: true,
        },
    };

    const censusSchema = {
        name: {
            textAlign: 'left',
            link: true,
        },
        email: {
            textAlign: 'left',
            link: true,
        },
        invited: {
            textAlign: 'center',
            permaSort: true,
            children: ({ datum }) =>
                datum.invited

                ?   <span className="fa-solid fa-check-circle edm-matched-active icon-green icon" />

                :   <span className="fa-solid fa-times-circle edm-matched-inactive icon-yellow icon" />
        },
        signed_up: {
            label: 'Active',
            textAlign: 'center',
            permaSort: true,
            children: ({ datum }) =>
                datum.signed_up

                ?   <span className="fa-solid fa-check-circle edm-matched-active icon-green icon" />

                :   <span className="fa-solid fa-times-circle edm-matched-inactive icon-yellow icon" />
        },
        invite: {
            label: ' ',
            children: ({ datum }) => 
                <button 
                    onClick={() => api.inviteEmployee(datum.id)}
                    className="btn-task"
                >
                    {datum.invited ? 'Resend Invite' : 'Send Invite'}
                </button>,
        }
    };

	useEffect(() => { 
	    api.getEmployees();
	}, []);

	const loading = state.loading.employees;
	const error = state.errors.employees;

	if (loading) return <Loader />;
	if (error) return <ErrorBox error={error} />;

	return(
        <div id="prospects">
            <div id="page-header">
                <div className="page-title">Roster</div>
            </div>
            <div id="section-header">
                <div className="section-title">Active Roster</div>
            </div>
            <Table
                schema={rosterSchema}
                data={state.employee_users}
                onLinkClick={(id) => window.location.href = `/employee/${id}`}
                onDestroy={(id) => api.destroyEmployee(id)}
            />
            <div id="section-header">
                <div className="section-title">Non-participating</div>
                <a
                    className="btn-orange"
                    onClick={api.inviteAllEmployees}
                >
                    <i className="fa-solid fa-share-square"></i>
                    &nbsp;
                    Invite All
                </a>
                <a
                    className="btn-orange"
                    id="new-prospect"
                    href={`/prospects/${state.plan.prospect_id}/employees/new`}
                >
                    <i className="fa-solid fa-plus-circle"></i>
                    &nbsp;
                    New Employee
                </a>
            </div>
            <SuccessBox success={state.success.invite_employee} />
            <ErrorBox error={state.errors.invite_employee} />
            <Table
                schema={censusSchema}
                data={state.employees}
                onLinkClick={(id) => window.location.href = `/employees/${id}`}
                onDestroy={(id) => api.destroyEmployee(id)}
            />
        </div>
    );
};

export default Roster;