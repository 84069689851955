import React, { useContext } from 'react';
import { Form } from '../components';
import { prospectParams } from '../params';
import Context from '../context';

const NewProspect = () => {
    const { state } = useContext(Context);

    return(
        <div id="new-prospect">
            <div id="page-header">
                <div className="page-title">Add Prospect</div>
                <a
                    className="btn-soft"
                    href="/prospects"
                >
                    <i className="fa-solid fa-caret-left"></i>
                    &nbsp;
                    {"Back"}
                </a>
            </div>
            <Form
                type="prospects"
                onSubmit="createProspect"
                submitCopy="Add Prospect"
                inputs={prospectParams({ admin: state.user.is_admin })}
            />
        </div>
    );
}

export default NewProspect;