import React, { useContext, useState } from 'react';
import Context from '../context';
import { BtnSpinner } from '.';
import { download } from '../utils';

const GenerateProposal = ({ copy, id, name, disabled=false }) => {
    const [loading, setLoading] = useState(false);
    const { api } = useContext(Context);

    async function generateProposal(e) {
        e.stopPropagation();
        if (!disabled) {
            setLoading(true);
            const url = await api.donwloadProposal(id); 
            setLoading(false);
            download(url, `${name} Proposal.pdf`);
        };
    };

    if (loading) return <BtnSpinner />

    return(
        <span
           className="icon-green icon"
           onClick={generateProposal}
        >
            <div className="card-button-wrapper">
                <div className="icon-text">
                    {copy ? <span>&nbsp;</span> : null}
                    {copy}
                </div>
                &nbsp;&nbsp;
                <i className="fa-solid fa-folder-download"></i>
            </div>
        </span>
    );
};

export default GenerateProposal;