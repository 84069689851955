import React, { useContext, useEffect } from 'react';
import Context from '../context';
import { Table, Loader, ErrorBox } from '../components';
import { parseQuery } from '../utils';



const Prospects = ({ location }) => {
    const { api, state } = useContext(Context);

    async function makeProspect(prospectId) {
        const res = await api.updateProspect(prospectId, { customer: false });

        if (res) {
            window.location.href = '/prospects';
        }
    }

    const tableSchema = {
        name: {
            bold: true,
            textAlign: 'left',
            link: true,
        },
        partner: {
            textAlign: 'left',
        },
        location: {
            textAlign: 'left',
        },
        employees: {
            textAlign: 'right',
        },
        status: {
            label: ' ',
            textAlign: 'right',
            children: ({ datum }) => (
                <div
                    onClick={() => makeProspect(datum.id)}
                    style={{ fontWeight: 'bold',cursor: 'pointer', color:'#e1795a'}}
                >
                    Make Prospect
                </div>
            )
        },
        download: {
            label: ' ',
            textAlign: 'right',
            children: ({ datum }) => (
                <a
                    href="/api/v1/customers/download"
                    download={true}
                    style={{ textDecoration: 'none', fontWeight: 'bold',cursor: 'pointer', color:'#e1795a'}}
                >
                    Download Docs
                </a>
            )
        },
    };

    useEffect(() => { 
        api.getCustomers();
    }, []);

    const loading = state.loading.customers;
    const error = state.errors.customers;

    if (loading) return <Loader />;
    if (error) return <ErrorBox error={error} />;

    return(
        <div id="prospects">
            <div id="page-header">
                <div className="page-title">Customers</div>
            </div>
            <Table
                schema={tableSchema}
                data={state.customers}
                onLinkClick={(id) => window.location.href = `/prospects/${id}`}
                onDestroy={(id) => api.destroyProspect(id)}
            />
        </div>
    );
}

export default Prospects;