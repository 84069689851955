import React from 'react';
import { InfoTooltip } from '../components';

const CostCard = ({ value, altValue, label, textColor, textWeight, href, info, potential }) => {

    function go() {
        if (href) window.location.href = href;
    }

    return(
        <div className="card" onClick={go} >
            <div className="card-wrapper">
                <div className="text text-label" style={{color: textColor}}>
                    {label}
                </div>
                {
                    info

                    ?   <div className="text-right hover">
                            <InfoTooltip copy={info} />
                        </div>

                    :   <div style={{height: '17px'}}></div>
                }
            </div>
            <div className="text text-value" style={{color: textColor, fontWeight: textWeight}}>
                {value || "0"}
            </div>
            {
                altValue
                
                ?   <div className="text text-details" style={{color: textColor, fontWeight: textWeight}}>
                        {altValue}
                    </div>

                :   null

            }
            <div className="card-wrapper">
                <div className="text text-potential" style={{color: textColor}}>{potential}</div>
            </div>
        </div>
    )
};

export default CostCard;