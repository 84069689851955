import React, { useContext, useState, useEffect } from 'react';
import { Form, ErrorBox } from '../components';
import Context from '../context';
import { capitalize } from '../utils';

const ClassForm = ({ onSubmit, klass }) => {
    const { api, state } = useContext(Context);
    const [strategy, setStrategy] = useState((klass && klass.reimbursement_strategy) || 'Fixed');
    const [level, setLevel] = useState((klass && klass.reimbursement_level) || 'Employee Only');
    // const [locationType, setLocationType] = useState(klass && klass.location_type || 'Employee');
    const [benchmark, setBenchmark] = useState(klass && klass.reimbursement_benchmark || '');
    const prospectId = state.design.prospect_id || state.plan.prospect_id;

    useEffect(() => {
        api.getPlans(prospectId, benchmark.toLowerCase());
    }, [ benchmark ]);

    async function handleSubmit(params) {
        const res = klass 

            ?   await api.updateClass(state.class.id, params)

            :   await api.createClass(state.design.id, params);

        if (res) onSubmit();
    }  

    return(
        <div className="card no hover">
            <div className="form-header">
                <i className="fa-solid fa-times icon-navy icon" onClick={onSubmit} />
                {klass 

                    ?   <span 
                            className="remove-item"
                            onClick={async () => {
                                if (window.confirm('Are you sure you want to delete this class?')) {
                                    const res = await api.destroyClass(klass.id);
                                    if (res) onSubmit();
                                }
                            }}
                        >
                            <i className="fa-solid fa-trash-alt icon-light-red icon"/>
                        </span> 

                    :   null
                }
            </div>
            <Form
                onSubmit={handleSubmit}
                submitCopy={klass ? 'Update Class' : 'Add Class'}
                type="classes"
                inputs={[
                    {
                        label: 'Employee Type',
                        placeholder: 'Salary',
                        type: 'select',
                        icon: 'fa-solid fa-money-check',
                        key: 'pay_types',
                        options: ['Salary', 'Non-salary', 'Non-salary & Salary'],
                        defaultValue: klass && capitalize(klass.pay_types),
                    },
                    {
                        label: 'Employee Status',
                        type: 'select',
                        icon: 'fa-solid fa-hourglass-start',
                        key: 'employee_types',
                        options: ['Full-time', 'Part-time', 'Full-time & Part-time', 'Seasonal'],
                        defaultValue: klass && capitalize(klass.employee_types),
                    },
                    {
                        label: 'Reimbursement Strategy',
                        type: 'select',
                        icon: 'fa-solid fa-chess',
                        info: 'Fixed is the simple, a set dollar amount available to each employee. Percentage is more complex, a variable amount to each employee based on the target plan cost.',
                        key: 'reimbursement_strategy',
                        options: ['Fixed', 'Percentage'],
                        onChange: ({ target }) => setStrategy(target.value),
                        defaultValue: klass && klass.reimbursement_strategy,
                    },
                    {
                        label: 'Coverage Level',
                        type: 'select',
                        icon: 'fa-solid fa-files-medical',
                        info: 'Reimburse for just the employee or the family as well.',
                        key: 'reimbursement_level',
                        options: ['Employee Only', 'Family'],
                        onChange: ({ target }) => setLevel(target.value),
                        defaultValue: klass && klass.reimbursement_level,
                    },
                    {
                        label: 'Target Plan Category',
                        type: 'select',
                        icon: 'fa-solid fa-medal',
                        info: 'Filter target plans by metal level',
                        key: 'reimbursement_benchmark',
                        options: ['Bronze', 'Silver', 'Gold'],
                        onChange: ({ target }) => setBenchmark(target.value),
                        defaultValue: klass && capitalize(klass.reimbursement_benchmark || ''),
                    },
                    // {
                    //     label: 'Location Type',
                    //     type: 'select',
                    //     key: 'location_type',
                    //     options: ['Employee', 'Employer'],
                    //     onChange: ({ target }) => setLocationType(target.value),
                    //     defaultValue: klass && klass.location_type,
                    // },
                    {
                        label: 'Target Plan',
                        type: 'select',
                        icon: 'fa-solid fa-bullseye-arrow',
                        info: 'The target plan is for calculation purposes only. Employees can choose any plan, it simply helps determine how much to reimburse!.',
                        key: 'rate_id',
                        options: state.plans,
                        // style: { display: locationType == 'Employer' ? '' : 'none' },
                        defaultValue: klass && klass.option_name,
                    },
                    {
                        label: `Employee Reimbursement Limit (${strategy == 'Fixed' ? '$' : '%'})`,
                        type: 'number',
                        icon:'fa-solid fa-envelope-open-dollar icon-green',
                        info: 'The amount the employer would like to reimburse for the employee.',
                        key: 'reimbursement_limit',
                        defaultValue: klass && klass.reimbursement_limit,
                    },
                    {
                        label: `Family Reimbursement Limit (${strategy == 'Fixed' ? '$' : '%'})`,
                        type: 'number',
                        icon: 'fa-solid fa-envelope-open-dollar icon-green',
                        info: 'The amount the employer would like to reimburse for additional family members.',
                        key: 'family_reimbursement_limit', 
                        style: { display: level == 'Family' ? '' : 'none' },
                        defaultValue: klass && klass.family_reimbursement_limit,
                    }
                ]}
            />
            {
                strategy == 'Percentage'

                &&  <ErrorBox error="The percentage strategy calculates the reimbursement limit available to the employee based of the selected percentage of the target plan base rate (21 year old rate) multiplied by age ratio of the employee or family member in accordance with ACA guidelines. The employees are not being reimbursed variable amounts based on their elected plan." />
            }
        </div>
    );
};

export default ClassForm;
