import React, { useContext, useState, useEffect } from 'react';
import { PasswordConditions, Form, ErrorBox, Loader } from '../components';
import Context from '../context';
import Logo from '../assets/logo.png';

const CompanyAdminSignUp = ({ match }) => {
    
    const { token } = match.params;
    const { api, state } = useContext(Context);

    const [password, setPassword] = useState('');
    const [passwordCopy, setPasswordCopy] = useState('');

    useEffect(() => {
        api.getCompanyAdminInvite(token);
    }, []);

    function onSubmit(params) {        
        if (PasswordConditions.allConditionsPass(password, passwordCopy)) {
            params.password = password;
            params.company = {};
            params.company.name = params.name;
            params.company.email = params.email;
            api.createCompanyAdmin(params);
        }
    }

    const error = state.errors.company_admin_invite;
    const loading = state.loading.company_admin_invite;
    const prospect = state.prospect;

    if (error) return(
         <div className="auth-container" >
            <img 
                className="auth-logo"
                src={Logo} 
                alt="401khealth logo" 
            />
            <ErrorBox error={error} />
        </div>
    );

    if (loading) return(
         <div className="auth-container" >
            <img 
                className="auth-logo"
                src={Logo} 
                alt="401khealth logo" 
            />
            <Loader />
        </div>
    );

    return(
        <div className="auth-container" >
            <div className="auth-wrapper">
                <div className="auth-header">
                    <div className="text text-heading">Welcome to 401k Health</div>
                    <div className="text text-sub">Let's get you started</div>
                </div>
                <img 
                    className="auth-logo"
                    src={Logo} 
                    alt="401khealth logo" 
                />
            </div>
            <div className="card card-welcome nohover">
                <div className="text text-body">
                    Getting started is simple. First, let's <b>create your account</b>, then you'll be able to setup your company,
                    verify plan details, and invite your employees. 
                </div>
            </div>
            <Form
                onSubmit={onSubmit}
                submitCopy="Create Account"
                type="company_admins"
                col="12"
                inputs={[
                    {   
                        label: 'First Name',
                        key: 'first_name',
                        type: 'text',
                    },
                    {   
                        label: 'Last Name',
                        key: 'last_name',
                        type: 'text',
                    },
                    {
                        label: 'Email',
                        type: 'email',
                        key: 'email',
                        defaultValue: prospect.email,
                    },
                    {
                        label: 'Company Name',
                        type: 'text',
                        key: 'name',
                        defaultValue: prospect.name,
                    },
                    {
                        type: 'hidden',
                        key: 'token',
                        defaultValue: token,

                    }

                ]}
            >
                <div className="col-md-12">
                    <div className="input-primary">
                        <label>New Password</label>
                        <input 
                            value={password}
                            onChange={({ target }) => setPassword(target.value)} 
                            type="password"
                        />
                    </div>
                    <div className="input-primary">
                        <label>Verify Password</label>
                        <input 
                            value={passwordCopy}
                            onChange={({ target }) => setPasswordCopy(target.value)} 
                            type="password"
                        />
                    </div>
                    <PasswordConditions password={password} passwordCopy={passwordCopy} />
                </div>
            </Form>
        </div>
    )
}

export default CompanyAdminSignUp;
