import React, { useContext } from 'react';
import Context from '../context';
import { DashboardCard, Todos, Loader, ErrorBox } from '../components';

const Dashboard = () => {
    const { state } = useContext(Context);

    const loading = state.loading.user;
    const error = state.errors.user;

    if (loading) return <Loader />;
    if (error) return <ErrorBox error={error} />;

    const { dashboard_data } = state.user;

    return(
        <div id="dashboard">
            <div id="page-header">
                <div className="page-title">Dashboard</div>
            </div>
            <div className="row">
                {dashboard_data.map((datum, i) =>
                    <div className="card-wrapper col-xs-6 col-sm-3">
                        <DashboardCard {...datum} />
                    </div>
                )}
            </div>
            <Todos />
        </div>
    );
}

export default Dashboard;