import React, { useState, useEffect, useContext } from 'react';
import { capitalize, onWindowExit, onWindowReEnter } from '../utils';
import Context from '../context';

const OnboardFlow = ({ steps={}, defaultStep=1, onStepChange }) => {
	const [currentStep, setCurrentStep] = useState(defaultStep);
	const [submitBtns, setSubmitBtns] = useState([]);
	const { api, state } = useContext(Context);

	function lastStep(step) {
		return step >= steps.length
	};

	function scrollTo(step) {
		document.getElementById(`step-${step}-header`)
				.scrollIntoView({ behavior: 'smooth' });
	};

	useEffect(() => {
		scrollTo(defaultStep);
	}, [defaultStep]);

	useEffect(() => {
		api.updateOnboard(currentStep);
	}, [currentStep])

	useEffect(() => {

		onWindowExit(
			document.querySelectorAll('.of-step-header, .of-step-bottom'),
			(e) => {
				const step = parseInt(e.getAttribute('data-step'));
				setCurrentStep(step);
			}
		);
			

	}, []);

	async function completeOnboarding() {
		const res = await api.updateOnboard();
		if (res) window.location.href = '/';
	}

	return(
		<div id="onboard-flow" >
			{/* <div id="of-steps">
				{steps.map((step, i) =>
					<div 
						className={`of-step ${i + 1 == currentStep ? 'current-step' : ''}`} 
						key={i}
						onClick={() => scrollTo(i + 1)} 
					>
						<div className="of-number">
							{i + 1}
						</div>
						<div className="of-label">
							{step.header}
						</div>
					</div>
				)}
			</div> */}
			<div id="of-wrapper" >
				{steps.map((step, i) =>
					<div key={i} className="of-step-wrapper" style={{minHeight: window.innerHeight * .8 }}>
						<h1 
							className='of-step-header' 
							id={`step-${i + 1}-header`}
							data-step={i + 1}
						>
							{step.header}
						</h1>
						<div className="of-step-description">
							{step.description}
						</div>
						{step.childComponent}
						{
							lastStep(i + 1)

							? 	null

							:	<div className="text-left">
									<a 
										className="btn-soft"
										onClick={() => scrollTo(i + 2)} 
									>
										Skip {step.header}
									</a>
								</div>
						}
						<div 
							className='of-step-bottom' 
							data-step={i + 2}
						/>
					</div>

				)}
				<div className="text-left">
					<button 
						className="btn-onboard"
						onClick={completeOnboarding}
					>
						Complete Onboarding
					</button>
					<button
						className='btn-orange ml-3'
						onClick={completeOnboarding}
					>
						Skip for now
					</button>
				</div>
			</div>
		</div>
	);
}

export default OnboardFlow;