import React, { useEffect, useContext } from 'react';
import Context from '../context';
import { Table, Loader, ErrorBox, UpstreamSettings } from '../components';

const Agencies = () => {
    const { api, state } = useContext(Context);

    function setAgency(agency) {
        return () => api.store.reduce({
            type: 'set_agency',
            agency,
        });
    }



    const schema = {
        name: {
            bold: true,
            textAlign: 'left',
        },
        city: {
            textAlign: 'left',
        },
        state: {
            textAlign: 'center',
        },
        exempt_from_payment: {
            textAlign: 'right',
            label: 'Exmempt from Payment?',
            children: ({ datum }) => <i className={`fa-solid fa-${datum.exempt_from_payment ? 'check' : 'times'}`} />
        },
        upstream_settings: {
            label: 'Settings',
            textAlign: 'right',
            children: ({ datum }) => <i className="fa-solid fa-cog" onClick={setAgency(datum)} />
        }
    };

    useEffect(() => {
        api.getAgencies();
    }, []);

    const loading = state.loading.accounts;
    const error = state.errors.accounts;

    if (loading) return <Loader />;
    if (error) return <ErrorBox error={error} />;

    return(
        <div id="prospects">
            <div id="page-header">
                <div className="page-title">Agencies</div>
            </div>
            <Table 
                schema={schema}
                data={state.accounts} 
            />
            <UpstreamSettings 
                {...state.agency}
                show={!!state.agency}
                onClose={setAgency(null)} 
            />
        </div>
    );
};

export default Agencies;
