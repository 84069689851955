import React, { useContext, useRef, useState } from 'react';
import { toUSD } from '../utils';
import { GenerateProposal, BtnSpinner } from '../components';
import Context from '../context';

const DesignCard = ({ id, name, class_count, employee_count, annual_cost, platform_fee, account_name }) => {
    const card = useRef();
    const { api } = useContext(Context);
    const [cloning, setCloning] = useState(false);

    function go() {
        window.location.href = `/prospects/designs/${id}`;
    }

    function destroy(e) {
        e.stopPropagation();
        if (window.confirm("Are you sure you want to delete this design?")) {
            api.destroyDesign(id);
            card.current.style.display = "none";
        }
    }

    async function clone(e) {
        setCloning(true);
        e.stopPropagation();
        const res = await api.cloneDesign(id);
        if (res) setCloning(false);
    }

    return(
        <div className="card" onClick={go} ref={card} >
            <div className="card-wrapper">
                <div className="text text-title">
                    {name}
                </div>
                <div className="card-button-wrapper text-right">
                    <GenerateProposal 
                        id={id}
                        name={name}
                        disabled={class_count == 0 || employee_count == 0}
                    />
                    {
                        cloning

                        ?   <BtnSpinner />

                        :   <i 
                                className="fa-solid fa-clone icon-navy icon" 
                                onClick={clone} 
                            />
                    }
                    <i 
                        className="fa-solid fa-trash-alt icon-light-red icon" 
                        onClick={destroy} 
                    />
                </div>
            </div>
            <div className="text text-description">
                {name} has <strong>{class_count} {class_count == 1 ? 'class' : 'classes'}</strong> and is estimated to cost the employer <strong>{annual_cost}</strong> per year in reimbursements and fees.
                {/* <br/>
                ICHRA could earn <strong>{account_name}</strong> up to <strong>{toUSD(platform_fee)}</strong> per month. */}
            </div>
        </div>
    );
};  

export default DesignCard;