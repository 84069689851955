import React, { useContext } from 'react';
import { Modal, Form } from '.';
import Context from '../context';

const AgencySettings = ({ show, onClose, success, ...agency }) => {
	const { api } = useContext(Context);

	function onSubmit(params) {
		api.updateAgency(agency.id, params)
	};
		
	return(
		<Modal
			heading="Agency Settings"
			show={show}
			onClose={onClose}
		>
			<Form
				type="update_agency"
				onSubmit={onSubmit}
				submitCopy="Update Agency"
				inputs={
				    [
				    	// {
				    	// 	label: 'Upstream Agency',
				    	// 	type: 'search',
				    	// 	query: 'searchAgencies',
				    	// 	key: 'upstream_account_id',
				    	// 	defaultValue: agency.upstream_name,
				    	// 	defaultId: agency.upstream_account_id,
				    	// },
				    	// {
				    	// 	label: 'Upstream Override ($)',
				    	// 	type: 'number',
				    	// 	key: 'upstream_override',
				    	// 	defaultValue: agency.upstream_override,
				    	// },
						{
							label: 'Exempt from Payment?',
							type: 'checkbox',
							key: 'exempt_from_payment',
							defaultValue: agency.exempt_from_payment,
							forceCheck: true,
						}
				    ]
				}
			/>
		</Modal>
	);
};

export default AgencySettings;