import React, { useContext, useState } from 'react';
import { OnboardFlow, Form, Classes, Table, PaymentMethodCard } from '.';
import { companyParams, planParams } from '../params';
import { toUSD, allTaskCardsSelected } from '../utils';
import Context from '../context';

const CompanyAdminOnboard = () => {
	const { state, api } = useContext(Context);
	const [step, setStep] = useState(state.account.onboard_step);
    const [bulkActivate, setBulkActivate] = useState(true);

	function activate(e, id) {
	    e.stopPropagation();
	    api.activateEmployee(state.plan.design_id, id);
	}

	function deactivate(e, id) {
	    e.stopPropagation();
	    api.deactivateEmployee(state.plan.design_id, id);
	}

	function bulk() {
	    if (bulkActivate) {
	        api.bulkActivate(state.plan.design_id);
	        setBulkActivate(false);
	    } else {
	        api.bulkDeactivate(state.plan.design_id);
	        setBulkActivate(true);
	    };
	}

	const rosterSchema = {
	    name: {
	        textAlign: 'left',
	        link: true,
	        bold: true,
	        defaultSort: true,
	    },
	    email: {
	    	textAlign: 'left'
	    },
	    active: {
	        label: 'Status',
	        width: '10px',
	        textAlign: 'center',
	        permaSort: true,
	        onClick: bulk,
	        children: ({ datum }) => (
	            <div>
	                {
	                    !datum.class

	                    ?   <span className="fa-solid fa-ban edm-unmatched icon-red icon" ></span>

	                    :   datum.active

	                        ?   <span className="fa-solid fa-check-circle edm-matched-active icon-green icon" onClick={(e) => deactivate(e, datum.id) } ></span>

	                        :   <span className="fa-solid fa-times-circle edm-matched-inactive icon-yellow icon" onClick={(e) => activate(e, datum.id) } ></span>
	                }
	            </div>
	        ),
	    },
	}

	async function updateCompany(params) {
        const res = await api.updateAccount(state.account.id, params);
        if (res) setStep(2);
    }

    async function updatePlan(params) {
    	if (allTaskCardsSelected()) {
	    	const res = await api.updatePlan(state.plan.id, params);
	    	if (res) setStep(3);
	    } else {
	    	api.setError('update_plan', 'Please approve all plan classes before saving your plan.');
	    }
    }

    async function handlePaymentSource(id) {
        const res = await api.attachPaymentMethod(state.account.id, id);
        console.log(res);
        if (res) completeOnboarding();
    }

    async function completeOnboarding() {
		const res = await api.updateOnboard();
		if (res) window.location.href = '/';
	}

	const steps = [
		{
			header: 'Company Setup',
			description: "Let’s make sure we have all your company information and it's correct.",
			childComponent: (
				<Form
	                onSubmit={updateCompany}
	                type="update_account"
	                submitCopy="Save"
	                inputs={companyParams(state.account)}
	            />
			),
		},
		{
			header: 'Plan Design',
			description: "Your broker prepared the following plan design, but we'll need to review and approve it.",
			childComponent: (
				<div>
					<Classes 
		                row="12" 
		                add={false} 
		                taskCards={true}
		            />
		            <Form
		            	onSubmit={updatePlan}
		            	type="update_plan"
		                submitCopy="Save"
		            	inputs={planParams(state.plan)}
		           	/>
		        </div>
			),
		},
		{
			header: 'Confirm Roster',
			description: "Make sure all your eligible employees are on the roster. They'll be invited later.",
			childComponent: (
				<div>
					<Table
                        schema={rosterSchema}
                        data={state.plan.employees}
                    />
				</div>
			),
		},
		{
			header: 'Finalize & Submit',
			description: `${toUSD(state.plan.platform_fee)} + ${toUSD(state.plan.subscription_fee)} per active employee per month`,
			childComponent: (
				<PaymentMethodCard 
					{...state.account.payment_method}
					onUpdate={handlePaymentSource}
				/>
			),
		}
	];

	return(
		<div id="dashboard">
		    <OnboardFlow 
		    	steps={steps}
		    	defaultStep={step}
		    />
		</div>
	)
};

export default CompanyAdminOnboard;