import React, { useContext, useState } from 'react';
import { ClassForm, TaskCard } from '../components';
import Context from '../context';

const Classes = ({ row, klasses, add=true, taskCards=false }) => {
    const { state, api } = useContext(Context);
    const classes =  state.classes || [];
    const klass = state.class;
    const [newClass, setNewClass] = useState(false);

    const loading = state.loading.classes;

    const KlassCard = (klass) => (
        <div className="card"> 
            <div className="card-wrapper">
                <div className="text text-title">{klass.name}</div>
                <div className="text-right">
                    <i className="fa-solid fa-pencil-alt icon-navy icon" onClick={() => api.setClass(klass.id)} />
                </div>
            </div>
            <div className="text-description">{klass.description}</div>
        </div>
    )

    return(
        <div id="classes">
            <div id="section-header">
                <div className="section-title">
                    <i className="fa-solid fa-users-class" />
                    &nbsp;
                    Employee Classes</div>
                {
                    add

                    ?   <a
                            className="btn-orange"
                            style={{
                                background: newClass || klass || classes.length > 3 ? '#E1E1E1' : '',
                            }}
                            onClick={() => setNewClass(prev => {
                                if (klass) {
                                    api.setClass(null);
                                    return false;
                                } else {
                                    if (classes.length > 3) {
                                        return false;
                                    } else {
                                        return !prev;
                                    }
                                }
                            })}
                        >
                            <i className="fa-solid fa-plus-circle"/>
                            &nbsp;
                            Class
                        </a>
                    
                    :   null
                }
            </div>
            {
                newClass || klass

                ?   <ClassForm 
                        onSubmit={() => {
                            setNewClass(false);
                            api.setClass(null);
                        }} 
                        klass={klass} 
                    />

                :   classes.length > 0

                    ?   <div className="row">
                            {classes.map((klass, i) =>
                                <div key={i} className={'col-12'}>
                                    {
                                        taskCards

                                        ?   <TaskCard>
                                                <KlassCard key={i} {...klass} />
                                            </TaskCard>

                                        :   <KlassCard key={i} {...klass} />
                                    }
                                </div>
                            )}
                        </div>

                    :   <div className="card card-prompt nohover">
                            <div className="card-header-wrapper">
                                <div className="text text-icon">
                                    <i className="fa-solid fa-whistle icon-light-navy icon"></i>
                                </div>
                                <div className="text text-description">You haven't created a class, yet. To get started, click the <span style={{ color: '#e1795a'}} >orange button</span> above.</div>
                            </div>
                        </div>
            }
        </div>
    )
};

export default Classes;