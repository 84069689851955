import React, { useContext } from 'react';
import Context from '../context';
import { Loader, ErrorBox } from '../components';

const Reimbursement = () => {
	const { state } = useContext(Context);

	const loading = state.loading.user;
	const error = state.errors.user;

	if (loading) return <Loader />;
	if (error) return <ErrorBox error={error} />;

	const { reimbursement } = state.user;

	const ReimbursementCard = ({ status, rejection_feedback, requested, limit, coverage_level, plan, coverage_upload }) => (
		<div className="card" >
            <div className="text text-description">
            	Status: <strong>{status}</strong>
            </div>
            {
                status === 'Rejected'

                ?   <div>Rejection Feedback: <strong>{rejection_feedback}</strong></div>

                :   null
            }
            <div className="text text-description">
            	Total Premium: <strong>{requested}</strong>
            </div>
            <div className="text text-description">
            	Allowance: <strong>{limit}</strong>
            </div>
            <div className="text text-description">
            	Coverage Level: <strong>{coverage_level}</strong>
            </div>
            <div className="text text-description">
            	Plan: <strong>{plan}</strong>
            </div>
            <a 
                id="btn-download"
                href={coverage_upload}
                download={true}
                target="_blank"
            >
                <i className="fa-solid fa-folder-download icon-navy icon"></i>
                View Proof of Coverage
            </a>
        </div>
	);

	return(
		<div id="dashboard">
            <div id="page-header">
                <div className="page-title">Your Reimbursement</div>
            </div>
            {
            	reimbursement

            	? 	<ReimbursementCard {...reimbursement} />

            	: 	<div className="card card-prompt">
				        <div className="card-header-wrapper">
				            <div className="text text-icon">
				                <i className="fa-solid fa-whistle icon-navy icon"></i>
				            </div>
				            <div className="text text-description" >
				            	Looks like you havent' requested a reimbursement yet! 
			            		<a href="/reimbursement/new" style={{ color: '#e1795a !important'}}>
			            			 Request one from your employer here!
			            		</a>
				            </div>
				        </div>
				    </div>
            }
        </div>
	);
};

export default Reimbursement;