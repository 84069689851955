import React, { useState } from 'react';
import { UpdateDesignFees } from '../components';

const PricingBreakdown = ({ reimbursements  }) => {
    const [show, setShow] = useState(false);

    if (show) return(
        <div id="pricing breakdown" >
            <div id="section-header">
                <div className="section-title">         
                    <i className="fa-solid fa-badge-dollar"></i>
                    &nbsp;
                    Cost Breakdown
                </div>
                {/* <a
                    className="btn-soft"
                    onClick={() => setShow(false)}
                >
                    {"Edit Fees"}
                    &nbsp;
                    <i className="fa-solid fa-caret-up"></i>
                </a> */}
                </div>
            <div className="card-prompt card no hover">
                <div className="card-wrapper">
                    <div>
                        <div className="text text-title">
                            Reimbursements
                            &nbsp;
                            <i className="fa-solid fa-envelope-open-dollar icon-green icon"></i>
                        </div>
                        <div className="text text-body">
                            The Reimbursement Limit is based on estimated participation and is the maximum amount the employer would be liable to reimburse under the plan desig.
                        </div>
                    </div>
                    <div className="text-right" >
                        <div className="text text-title">
                            {reimbursements}
                        </div>
                        <div className="text text-body">
                            company /mo.
                        </div>
                    </div>
                </div>
                <br/>
                <UpdateDesignFees>
                    {({ platformFee, subscriptionFee, updatePlatformFee, updateSubscriptionFee }) => (
                        <div>
                            <div className="card-wrapper">
                                <div>
                                    <div className="text text-title">
                                        401k Health Subscription Fee
                                    </div>
                                    <div className="text text-body">
                                        The Subscription Fee is for access to the HRA plan adminstration software, plan document management, employee reimbursement, & access to medical benefit resources for employees.
                                    </div>
                                </div>
                                <div className="text-right" >
                                    <div>
                                        <div className="input-primary" style={{maxWidth: '110px', display: 'flex', alignItems: 'center'}} >
                                            $
                                            <input 
                                                style={{marginLeft: '5px', textAlign: 'right'}}
                                                type="number" 
                                                value={subscriptionFee} 
                                                onChange={updateSubscriptionFee}
                                            />
                                        </div>
                                        <div className="text text-body">
                                            user /mo.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br/>
                            <div className="card-wrapper">
                                <div>
                                    <div className="text text-title">
                                        Design and Support Fee
                                    </div>
                                    <div className="text text-body">
                                        The Design and Support Fee is for custom HRA plan design expertise and ongoing support form your broker partner.
                                    </div>
                                </div>
                                <div className="text-right" >
                                    <div>
                                        <div className="input-primary" style={{maxWidth: '110px', display: 'flex', alignItems: 'center'}} >
                                            $
                                            <input 
                                                style={{marginLeft: '5px', textAlign: 'right'}}
                                                type="number" 
                                                value={platformFee}
                                                onChange={updatePlatformFee}
                                            />
                                        </div>
                                        <div className="text text-body">
                                            company /mo.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </UpdateDesignFees>
                <br/>    
                <div className="card-header-wrapper">
                    <i className="fa-solid fa-traffic-cone icon-navy icon nohover" />
                    <div className="text text-details">Heads Up! The fees are factored into the over all price of the Plan Design. Projected pricing is an estimate.</div>
                </div>
            </div>
        </div>
    );

    return(
        <div id="section-header">
            <div className="section-title">
                <i className="fa-solid fa-badge-dollar"></i>
                &nbsp;
                Cost Breakdown
            </div>
            {/* <a
                className="btn-soft"
                onClick={() => setShow(true)}
            >
                {"Edit Fees"}
                &nbsp;
                <i className="fa-solid fa-caret-down"></i>
            </a> */}
        </div>
    )
};

export default PricingBreakdown;