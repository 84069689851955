import React, { useContext, useEffect } from 'react';
import Context from '../context';
import { Table, Loader, ErrorBox } from '../components';
import { parseQuery } from '../utils';



const Prospects = ({ location }) => {
    const { api, state } = useContext(Context);

    async function makeCustomer(prospectId) {
        const res = await api.updateProspect(prospectId, { customer: '1' });

        if (res) {
            window.location.href = '/customers';
        }
    }

    const tableSchema = {
        name: {
            bold: true,
            textAlign: 'left',
            link: true,
        },
        broker: {
            textAlign: 'left',
        },
        location: {
            textAlign: 'left',
        },
        employees: {
            textAlign: 'right',
        },
        status: {
            label: ' ',
            textAlign: 'right',
            children: ({ datum }) => (
                <div onClick={() => makeCustomer(datum.id)} style={{ fontWeight: 'bold',cursor: 'pointer', color:'#e1795a'}}>Make Customer</div>
            )
        },
    };

    useEffect(() => { 
        const params = parseQuery(location.search);
        api.getProspects(params);
    }, []);

    const loading = state.loading.prospects;
    const error = state.errors.prospects;

    if (loading) return <Loader />;
    if (error) return <ErrorBox error={error} />;

    return(
        <div id="prospects">
            <div id="page-header">
                <div className="page-title">Prospects</div>
                <a
                    className="btn-orange"
                    id="new-prospect"
                    href="/prospects/new"
                >
                    <i className="fa-solid fa-plus-circle"></i>
                    &nbsp;
                    New Prospect
                </a>
            </div>
            <Table
                schema={tableSchema}
                data={state.prospects}
                onLinkClick={(id) => window.location.href = `/prospects/${id}`}
                onDestroy={(id) => api.destroyProspect(id)}
            />
        </div>
    );
}

export default Prospects;