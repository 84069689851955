import React, { useContext, useEffect, useState } from 'react';
import { FileUpload, ErrorBox, SuccessBox, Loader, TaskCard, Form } from '../components';
import Context from '../context';
import { toBase64, allTaskCardsSelected } from '../utils';

const NewReimbursement = () => {
	const { api, state } = useContext(Context);
    const [issuer, setIssuer] = useState(null);

    useEffect(() => {
        api.getRates();
    }, []);

	async function upload(file) {
		const res = await toBase64(file);
	    api.uploadCoverageDocument(res);
	};

	const loading = state.loading.user;
	const error = state.errors.user;

	if (loading) return <Loader />;
	if (error) return <ErrorBox error={error} />;

	const { coverage_upload } = state.user;
	
	function onSubmit(params) {
		if (allTaskCardsSelected() && coverage_upload) {
			api.createReimbursement(params);
		} else {
			api.store.reduce({
				type: 'error',
				errorType: 'coverage_upload',
                error: 'Please complete the request, upload a proof of coverage, and check the boxes before you submit.',
			});
		}
	};

    function filterPlans(issuer) {
        if (!issuer) return ['Please select an issuer.'];

        return state.plans.reduce((mem, plan) => {
            if (plan[0] === issuer) {
                mem[plan[2]] = plan[1];
            };
            return mem;
        }, {});
    };

	const { reimbursement } = state.user;

	return(
		<div id="dashboard">
            <div id="page-header">
                <div className="page-title">Setup Reimbursement</div>
            </div>
            <ErrorBox error={state.errors.coverage_upload} />
            <SuccessBox success={state.success.coverage_upload} />
            <div className="card card-census" style={{marginBottom: '50px'}}>
                <div className="card-wrapper">
                    <div className="card-header-wrapper">
                        <div className="text text-icon nohover">
                            <i className="fa-solid fa-file-upload icon-navy icon"></i>
                        </div>
                        <div>
                            <div className="text text-title">Proof of Coverage</div>
                        </div>
                    </div>
                    
                    <div className="card-header-wrapper hover">
	                    {
	                    	coverage_upload

	                    	?	<a 
			                        id="btn-download"
			                        href={coverage_upload}
			                        download={true}
			                        target="_blank"
			                    >
			                        <i className="fa-solid fa-folder-download icon-navy icon"></i>
			                        View Proof of Coverage
			                    </a>

			                : 	null
	                    }
                        <FileUpload onUpload={upload} type="coverage_upload" copy={coverage_upload ? 'Upload New File' : 'Upload File'} />
                    </div>
                </div>
            </div>
            <Form
            	onSubmit={onSubmit}
                submitCopy="Submit for Review"
                type="reimbursements"
                inputs={[
                    {
                        label: 'First Name',
                        key: 'first_name',
                        type: 'text',
                        defaultValue: state.user.first_name,
                    },
                    {
                        label: 'Last Name',
                        key: 'last_name',
                        type: 'text',
                        defaultValue: state.user.last_name,
                    },
                    {
                        label: 'Address',
                        key: 'address',
                        type: 'text',
                    },
                    {
                        label: 'Birthdate',
                        type: 'date',
                        key: 'birthdate',
                        defaultValue: state.user.birthdate,
                    },
                    {
                        label: 'Phone Number',
                        type: 'text',
                        key: 'phone_number',
                    },
                    {
                        label: 'Dependents (Participants Only)',
                        key: 'coverage_dependents_attributes',
                        type: 'list',
                        inputs: [
                            {
                                label: 'Name',
                                key: 'name',
                                type: 'text',
                            },
                            {
                                label: 'Birthdate',
                                key: 'birthdate',
                                type: 'date',
                            }
                        ]
                    },
                	{
                		label: 'Total Premium ($)',
                		key: 'total_premium',
                		type: 'number',
                	},
                	{
                		label: 'Coverage Level',
                		key: 'coverage_level',
                		type: 'select',
                		options: {
                			individual: 'Employee',
                			family: 'Employee and Family',
                		},
                		defaultValue: state.user.coverage_level,
                	},
                    {
                        label: 'Carrier',
                        key: 'carrier',
                        type: 'select',
                        options: state.issuers,
                        onChange: ({ target }) => setIssuer(target.value),
                    },
                    {
                        label: 'Plan',
                        key: 'rate_id',
                        type: 'select',
                        options: filterPlans(issuer),
                    },
                    {
                        label: 'Start Date',
                        key: 'start_date',
                        type: 'date',
                    }
                ]}
            >
	            <TaskCard >
	            	<div div className="text text-details" >
                        I hereby attest that the information I have provided above is true and accurate, I have enrolled myself and my dependents in eligible individual health coverage, and that I understand that I cannot receive any reimbursement until I provide evidence of our enrollment in such coverage and I have to provide such evidence thereafter as requested by my Employer.
                    </div>
	            </TaskCard>
                <TaskCard >
                    <div className="text text-details" >
                        I hereby certify that the expenses attached and shown above have not been and will not be paid or reimbursed by a medical plan.
                    </div>
                </TaskCard>
            </Form>
        </div>
	);
}

export default NewReimbursement;