import React, { useContext } from 'react';
import Context from '../context';

const Todos = () => {
	const { state } = useContext(Context);
	const { todos } = state.user;

	return(
		<div>
			<div className="section-title">Things to do</div>
			{todos.map((todo, i) =>
				<div className="onboarding-box" key={i} >
				    <div>
				        <h3>{todo.name}</h3>
				        <div>{todo.description}</div>
				    </div>
				    <a className="btn-task" href={todo.href} >Complete</a>
				</div>
			)}
			{
				todos.length == 0 

				?	<div className="card card-success nohover">
						<div className="card-header-wrapper">
							<div className="text text-icon">
								<i className="fa-solid fa-hands-wash icon"></i>
							</div>
							<div className="text text-description">
								You're all caught up on your todo's.
							</div>
						</div>
					</div>
					
				: 	null
			}
		</div>
	);
};

export default Todos;