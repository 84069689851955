import { store } from './store';

class Api {
    baseUrl = '/api/v1/'
    authToken = localStorage.getItem('authToken')
    railsToken = document.getElementsByName('csrf-token')[0].content
    store = store

    get = async (path, { params={}, url=this.baseUrl, method='GET', checkRefresh=true, errorType='standard' } = {}) => {
        if (checkRefresh) await this.maybeRefreshToken();

        const json = await fetch(url  + path + this.querify(params), {
            method,
            headers: {
                'Authorization': 'Bearer ' + this.authToken,
                'X-CSRF-Token': this.railsToken
            }
        });

        return await this.handleResponse(json, errorType);
    }

    querify = (query) => {
        const pairs = Object.keys(query).map(key => {
            return key + '=' + query[key];
        });

        return '?' + pairs.join('&');
    }

    post = async (path, { body, params={}, url=this.baseUrl, method='POST', contentType='application/json', checkRefresh=true, errorType='standard' } = {}) => {
        if (checkRefresh) await this.maybeRefreshToken();

        const json = await fetch(url + path, {
            method,
            headers: {
                'Authorization': 'Bearer ' + this.authToken,
                'X-CSRF-Token': this.railsToken,
                'Content-Type': contentType,
            },
            body: body || JSON.stringify(params),
        });

        return await this.handleResponse(json, errorType);
    }

    download = async (path, checkRefresh=true) => {
        if (checkRefresh) await this.maybeRefreshToken();

        const res = await fetch(this.baseUrl  +  path, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + this.authToken,
                'X-CSRF-Token': this.railsToken
            }
        });
        const blob = await res.blob();
        const url = window.URL.createObjectURL(blob);
        return url;
    }

    waitTwoSecondsThen = (response) => {
        return new Promise((resolve) =>
            setTimeout(() => resolve(response), 2000)
        )
    }

    maybeRefreshToken = async () => {
        const tokenExpires = new Date(localStorage.getItem('tokenExpires'));
        const now = new Date();

        if (tokenExpires && now > tokenExpires) {

            const res = await this.get('refresh_token', {
                errorType: 'login',
                checkRefresh: false,
            });

            this.authToken = res.auth_token;
            store.reduce({
                type: 'login',
                token: res.auth_token,
                user: res.user,
            });

        }
    }

    handleResponse = async (json, errorType) => {
        if (json.status === 401) {

            window.location.href = '/login';
            return {};

        } else if (json.status === 402) {

            window.location.href = '/subscribe';
            return {};

        } else {

            const res = await json.json();

            if (res.error) {

                store.reduce({
                    type: 'error',
                    errorType,
                    error: res.error,
                });

                return { error: true };

            } else {

                return res;

            }

        }
    }
    
    createUser = async (user) => {
        this.setLoading('login');

        const res = await this.post('sign_up', { 
            params: { user },
            errorType: 'login',
            checkRefresh: false,
        });

        if (!res.error) {

            store.reduce({
                type: 'login',
                token: res.auth_token,
                user: res.user,
            });

            window.location.href = "/";

            return true;

        } else {

            return false;

        }
    }


    login = async (user, redirectTo="/") => {
        this.setLoading('login');

        const res = await this.post('login', {
            params: { user },
            errorType: 'login',
            checkRefresh: false,
        });

        if (!res.error) {

            store.reduce({
                type: 'login',
                token: res.auth_token,
                user: res.user,
            });

            window.location.href = redirectTo;

            return true;

        } else {

            return false;

        }
    }

    getUser = async () => {
        this.setLoading('user');

        const res = await this.get('current_user', {
            errorType: 'user',
        });
        
        if (!res.error)
            store.reduce({
                type: 'set_user',
                user: res.user,
            });
    }

    updateUser = async (id, user, type='update_user') => {
        this.setLoading(type);

        const res = await this.post(`users/${id}`, { 
            params: { user },
            errorType: type,
            method: 'PATCH',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_user',
                user: res.user,
            });

            return true;

        } else {

            return false;

        }
    }

    updateProfilePicture = async (id, image, type='profile_picture') => {
        this.setLoading(type);

        const res = await this.post(`users/${id}/profile_picture`, { 
            params: { image },
            errorType: type,
        });

        if (!res.error) {

            store.reduce({
                type: 'set_user',
                user: res.user,
            });

            return true;

        } else {

            return false;

        }
    }

    destroyUser = async (id) => {
        this.get(`users/${id}`, {
            method: 'DELETE',
            errorType: 'users',
        });
    }

    updateOnboard = async (step=null) => {
        const res = await this.post('onboard', {
            params: { step }
        });

        if (!res.error) {

            return true;

        } else {

            return false;
        }
    }

    getAgencies = async () => {
        this.setLoading('accounts');

        const res = await this.get('agencies', {
            errorType: 'accounts',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_accounts',
                accounts: res.accounts,
            });

            return true;

        } else {

            return false;

        }
    }


    updateAccount = async (id, account, index=false) => {
        this.setLoading('onboarding');
        this.setLoading('update_account');

        const res = await this.post(`accounts/${id}`, { 
            params: { account, index },
            errorType: 'update_account',
            method: 'PATCH',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_account',
                index,
                account: res.account,
            });

            store.reduce({
                type: 'success',
                successType: 'update_account',
                success: 'Successfully updated your agency!',
            });

            return true;

        } else {

            return false;

        }
    }

    updateAgency = async (id, agency) => {
        this.setLoading('update_agency');

        const res = await this.post(`agencies/${id}`, { 
            params: { agency },
            errorType: 'update_agency',
            method: 'PATCH',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_account',
                index: true,
                account: res.agency,
            });

            store.reduce({
                type: 'success',
                successType: 'update_agency',
                success: 'Successfully updated your agency!',
            });

            return true;

        } else {

            return false;

        }
    }

    attachPaymentMethod = async (id, payment_method_id) => {
        this.setLoading('onboarding');

        const res = await this.post(`accounts/${id}/payment_method`, {
            params: { payment_method_id },
            errorType: 'payment_method',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_account',
                account: res.account,
            });

            store.reduce({
                type: 'success',
                successType: 'payment_method',
                success: 'Successfully added payment method!',
            });

            return true;

        } else {

            return false;

        }
    }

    applyCoupon = async (id, params) => {
        this.setLoading('coupons');

        const res = await this.post(`accounts/${id}/coupons`, {
            params,
            errorType: 'coupons',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_account',
                account: res.account,
            });

            store.reduce({
                type: 'success',
                successType: 'coupons',
                success: 'Successfully applied your coupon!',
            });

            return true;

        } else {

            return false;

        }
    }

    attachExternalAccount = async (id, token) => {
        this.setLoading('onboarding');
        
        const res = await this.post(`accounts/${id}/external_account`, {
            params: { token },
            errorType: 'payment_method',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_account',
                account: res.account,
            });

            store.reduce({
                type: 'success',
                successType: 'payment_method',
                success: 'Successfully added external account!',
            });

        } else {

            return false;

        }
    }

    updatePaymentPlan = async (id, plan_type) => {
        const res = await this.post(`accounts/${id}/payment_plan`, {
            params: { plan_type },
            errorType: 'plans',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_account',
                account: res.account,
            });

            store.reduce({
                type: 'success',
                successType: 'plans',
                success: 'Successfully updated your payment plan.',
            });

            return true;

        } else {

            return false;

        }
    }

    createSubscription = async (id) => {
        const res = await this.post(`accounts/${id}/create_subscription`, {
            errorType: 'plans',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_account',
                account: res.account,
            });

            store.reduce({
                type: 'success',
                successType: 'plans',
                success: 'Successfully started your plan.',
            });

            return true;

        } else {

            return false;

        }
    }

    cancelSubscription = async (id) => {
        const res = await this.post(`accounts/${id}/cancel_subscription`, {
            errorType: 'plans',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_account',
                account: res.account,
            });
            
            store.reduce({
                type: 'success',
                successType: 'plans',
                success: 'Successfully cancelled your plan.',
            });

            return true;

        } else {

            return false;

        }
    }

    createPasswordReset = async (email) => {
        this.setLoading('login');

        return await this.post('reset_password', {
            params: { email },
            errorType: 'login',
            checkRefresh: false,
        });

    }

    changePassword = async (params) => {
        this.setLoading('login');

        return await this.post('change_password', {
            params,
            errorType: 'login',
            checkRefresh: false,
        });

    }

    createProspect = async (prospect) => {
        this.setLoading('prospects');

        const res = await this.post('prospects', { 
            params: { prospect },
            errorType: 'prospects',
        });

        if (!res.error) {

            store.reduce({
                type: 'add_prospect',
                prospect: res.prospect,
            });

            window.location.href = '/prospects';

            return true;

        } else {

            return false;

        }
    }

    getProspects = async (params={}) => {
        this.setLoading('prospects');

        const res = await this.get('prospects', {
            params,
            errorType: 'prospects',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_prospects',
                prospects: res.prospects,
            });

            return true;

        } else {

            return false;

        }
    }

    getCustomers = async (params={}) => {
        this.setLoading('customers');

        const res = await this.get('customers', {
            params,
            errorType: 'customers',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_customers',
                customers: res.customers,
            });

            return true;

        } else {

            return false;

        }
    }

    updateProspect = async (id, prospect) => {
        this.setLoading('update_prospect');

        const res = await this.post(`prospects/${id}`, { 
            params: { prospect },
            errorType: 'update_prospect',
            method: 'PATCH',
        });

        if (!res.error) {

            store.reduce({
                type: 'update_prospect',
                prospect: res.prospect,
            });

            return true;

        } else {

            return false;

        }
    }

    getProspect = async (id) => {
        this.setLoading('prospects');

        const res = await this.get(`prospects/${id}`, {
            errorType: 'prospects',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_prospect',
                prospect: res.prospect,
            });

            return true;

        } else {

            return false;

        }
    }

    destroyProspect = async (id) => {
        this.get(`prospects/${id}`, {
            method: 'DELETE',
            errorType: 'prospects',
        });
    }

    getPlans = async (prospectId, metal) => {
        const res = await this.post(`prospects/${prospectId}/plans`, {
            params: { metal },
        });

        if (!res.error) {

            store.reduce({
                type: 'set_plans',
                plans: res.plans,
            });

            return true;

        } else {

            return false;

        };
    }

    createEmployee = async (prospectId, employee) => {
        this.setLoading('employees');

        const res = await this.post(`prospects/${prospectId}/employees`, { 
            params: { employee },
            errorType: 'employees',
        });

        if (!res.error) {

            window.location = document.referrer;
            
            return true;

        } else {

            return false;

        }
    }

    createEmployees = async (prospectId, employees=[]) => {
        this.setLoading('bulk_employees');
        
        const res = await this.post(`prospects/${prospectId}/employees/bulk`, { 
            params: { employees },
            errorType: 'bulk_employees',
        });

        if (!res.error) {

            localStorage.setItem('success', 'Successfully added your employees!');

            window.location = document.referrer;
            
            return true;

        } else {

            return false;

        }
    }

    getEmployee = async (id) => {
        this.setLoading('employees');

        const res = await this.get(`employees/${id}`, {
            errorType: 'employees',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_employee',
                employee: res.employee,
            });
            
            return true;

        } else {

            return false;

        }
    }

    updateEmployee = async (id, employee) => {
        this.setLoading('update_employee');

        const res = await this.post(`employees/${id}`, { 
            params: { employee },
            errorType: 'update_employee',
            method: 'PATCH',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_employee',
                employee: res.employee,
            });

            return true;

        } else {

            return false;

        }
    }

    destroyEmployee = async (id) => {
        this.get(`employees/${id}`, {
            method: 'DELETE',
            errorType: 'employees',
        });
    }

    inviteEmployee = async (id) => {
        this.setLoading('invite_employee');

        const res = await this.post(`employees/${id}/invite`, {
            errorType: 'invite_employee'
        });

        if (!res.error) {

            store.reduce({
                type: 'success',
                successType: 'invite_employee',
                success: 'Employee invite has been sent!',
            });

            store.reduce({
                type: 'update_employee',
                ...res,
            });

            return true;

        } else {

            return false;

        }
    }

    inviteAllEmployees = async () => {
        this.setLoading('invite_employee');

        const res = await this.post(`employees/invite_all`, {
            errorType: 'invite_employee'
        });

        if (!res.error) {

            store.reduce({
                type: 'success',
                successType: 'invite_employee',
                success: 'Employee invites have been sent!',
            });

            store.reduce({
                type: 'set_employees',
                ...res,
            });

            return true;

        } else {

            return false;

        }
    }

    getEmployeeInvite = async (token) => {
        this.setLoading('employee_invite');

        const res = await this.get(`employees/invite/${token}`, {
            errorType: 'employee_invite',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_employee',
                ...res,
            });

            return true;

        } else {

            return false;

        }
    }

    createDesign = async (designId) => {
        this.setLoading('designs');

        const res = await this.post(`prospects/${designId}/designs`, { 
            errorType: 'designs',
        });

        if (!res.error) {

            window.location.href = `/prospects/designs/${res.design.id}`;
            
            return true;

        } else {

            return false;

        }
    }

    getDesign = async (id) => {
        this.setLoading('designs');

        const res = await this.get(`designs/${id}`, {
            errorType: 'designs',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_design',
                design: res.design,
            });
            
            return true;

        } else {

            return false;

        }
    }

    cloneDesign = async (id) => {
        this.setLoading('designs');

        const res = await this.post(`designs/${id}/clone`, {
            errorType: 'designs',
        });

        if (!res.error) {

            store.reduce({
                type: 'add_design',
                design: res.design,
            });
            
            return true;

        } else {

            return false;

        }
    }

    updatePlatformFee = async (designId, platformFee) => {
        this.setLoading('update_design');

        const res = await this.post(`designs/${designId}/platform_fee`, { 
            params: { platform_fee: platformFee },
            errorType: 'update_design',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_design',
                design: res,
            });

            return true;

        } else {

            return false;

        }
    }

    updateSubscriptionFee = async (designId, subscriptionFee) => {
        this.setLoading('update_design');

        const res = await this.post(`designs/${designId}/subscription_fee`, { 
            params: { subscription_fee: subscriptionFee },
            errorType: 'update_design',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_design',
                design: res,
            });

            return true;

        } else {

            return false;

        }
    }

    updateDesignName = async (designId, name) => {
        this.setLoading('update_design');

        const res = await this.post(`designs/${designId}/name`, { 
            params: { name },
            errorType: 'update_design',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_design',
                design: { name: res.name },
            });

            return true;

        } else {

            return false;

        }
    }

    destroyDesign = async (id) => {
        this.get(`designs/${id}`, {
            method: 'DELETE',
            errorType: 'designs',
        });
    }

    activateEmployee = async (designId, employeeId) => {
        this.setLoading('update_design');

        const res = await this.post(`designs/${designId}/activate_employee`, { 
            params: { employee_id: employeeId },
            errorType: 'update_design',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_design',
                design: res,
            });

            return true;

        } else {

            return false;

        }
    }

    deactivateEmployee = async (designId, employeeId) => {
        this.setLoading('update_design');

        const res = await this.post(`designs/${designId}/deactivate_employee`, { 
            params: { employee_id: employeeId },
            errorType: 'update_design',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_design',
                design: res,
            });

            return true;

        } else {

            return false;

        }
    }

    bulkActivate = async (designId) => {
        this.setLoading('update_design');

        const res = await this.post(`designs/${designId}/bulk_activate`, { 
            errorType: 'update_design',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_design',
                design: res,
            });

            return true;

        } else {

            return false;

        }
    }

    bulkDeactivate = async (designId) => {
        this.setLoading('update_design');

        const res = await this.post(`designs/${designId}/bulk_deactivate`, { 
            errorType: 'update_design',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_design',
                design: res,
            });

            return true;

        } else {

            return false;

        }
    }


    createClass = async (designId, klass) => {
        this.setLoading('classes');

        const res = await this.post(`designs/${designId}/classes`, { 
            params: { class: klass },
            errorType: 'classes',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_design',
                design: res.design,
            });

            return true;

        } else {

            return false;

        }
    }

    setClass = async (id) => {
        store.reduce({
            type: 'set_class', id
        })
    }

    updateClass = async (id, klass) => {
        this.setLoading('classes');

        const res = await this.post(`classes/${id}`, { 
            params: { class: klass },
            errorType: 'classes',
            method: 'PATCH',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_design',
                design: res.design,
            });

            return true;

        } else {

            return false;

        }
    }

    destroyClass = async (id) => {
        const res = await this.get(`classes/${id}`, {
            method: 'DELETE',
            errorType: 'classes',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_design',
                design: res.design,
            });

            return true;
            
        } else {

            return false;

        }
    }

    getAgents = async (params={}) => {
        if (!params.offset) this.setLoading('agents');

        const res = await this.get('agents', {
            params,
            errorType: 'agents',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_agents',
                agents: res.agents,
            });

            return true;

        } else {

            return false;

        }

    }

    updateAgent = async (id, agent) => {
        this.setLoading('update_agent');

        const res = await this.post(`agents/${id}`, { 
            params: { agent },
            errorType: 'update_agent',
            method: 'PATCH',
        });

        if (!res.error) {

            window.location.reload();

        } else {

            return false;

        }
    }

    invitePartner = async (invite) => {
        this.setLoading('invite_partner');

        const res = await this.post('invite_partner', {
            params: { invite },
            errorType: 'invite_partner',
        });

        if (!res.error) {

            store.reduce({
                type: 'success',
                successType: 'invite_partner',
                success: 'Invite has been sent!',
            });

            return true;

        } else {

            return false; 

        }
    }

    searchPartners = async (term) => {
        const res = await this.post('search_partners', {
            params: { term }
        });

        if (!res.error) {

            store.reduce({
                type: 'search_results',
                searchType: 'searchPartners',
                results: res.partners,
            });

            return true;

        } else {

            return false;

        }
    }

    searchAgencies = async (term) => {
        const res = await this.post('agencies/search', {
            params: { term }
        });

        if (!res.error) {

            store.reduce({
                type: 'search_results',
                searchType: 'searchAgencies',
                results: res.agencies,
            });

            return true;

        } else {

            return false;

        }
    }

    donwloadProposal = async (id) => {        
        return this.download(`designs/${id}/generate_proposal`);
    }

    downloadPlanDocument = async (id) => {        
        return this.download(`plans/${id}/document`);
    }

    downloadAdoptionAgreement = async (id) => {        
        return this.download(`plans/${id}/adoption_agreement`);
    }



     ////////////////////////////
    //// COMPANY ADMIN CALLS ///
   ////////////////////////////

    inviteCompanyAdmin = async (params) => {
        this.setLoading('company_admins');

        const res = await this.post('company_admins/invite', {
            params,
            errorType: 'company_admins'
        });

        if (!res.error) {

            store.reduce({
                type: 'success',
                successType: 'company_admins',
                success: 'Invite has been sent!',
            });

            return true;

        } else {

            return false;

        }
    }

    getCompanyAdminInvite = async (token) => {
        this.setLoading('company_admin_invite');

        const res = await this.get(`company_admins/invite/${token}`, {
            errorType: 'company_admin_invite',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_prospect',
                prospect: res.prospect,
            });

            return true;

        } else {

            return false;

        }
    }

    createCompanyAdmin = async (company_admin) => {
        this.setLoading('company_admins');

        const res = await this.post(`company_admins`, { 
            params: { company_admin },
            errorType: 'company_admins',
        });

        if (!res.error) {

            store.reduce({
                type: 'login',
                token: res.auth_token,
                user: res.user,
            });

            window.location.href = "/";

            return true;

        } else {

            return false;

        }
    }

    updatePlan = async (id, plan) => {
        this.setLoading('update_plan');

        const res = await this.post(`plans/${id}`, { 
            params: { plan },
            errorType: 'update_plan',
            method: 'PATCH',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_plan',
                plan: res.plan,
            });

            return true;

        } else {

            return false;

        }
    }

    getEmployees = async () => {
        this.setLoading('employees');

        const res = await this.get('employees', {
            errorType: 'employees',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_employees',
                ...res,
            });

            return true;

        } else {

            return false;
            
        }
    }


     ////////////////////////////
    //// EMPLOYEE USER CALLS ///
   ////////////////////////////

   createEmployeeUser = async (employee) => {
        this.setLoading('employees');

        const res = await this.post('employee_users', { 
            params: { employee },
            errorType: 'employees',
        });

        if (!res.error) {

            store.reduce({
                type: 'login',
                token: res.auth_token,
                user: res.user,
            });

            window.location.href = "/";

            return true;

        } else {

            return false;

        }
    }   



    createProofOfCoverage = async (proof_of_coverage) => {
        this.setLoading('prove_coverage');

        const res = await this.post('proof_of_coverage', { 
            params: { proof_of_coverage },
            errorType: 'prove_coverage',
        });

        if (!res.error) {

            window.location.href = "/reimbursement";

            return true;

        } else {

            return false;

        }
    }

    uploadCoverageDocument = async (file) => {
        this.setLoading('coverage_upload');

        const res = await this.post('coverage_upload', {
            params: { file }, 
            errorType: 'coverage_upload',
        });

        if (!res.error) {

            store.reduce({
                type: 'success',
                successType: 'coverage_upload',
                success: 'Successfully uploaded proof of coverage!',
            });

            store.reduce({
                type: 'set_user',
                loadingType: 'coverage_upload',
                ...res,
            });

            return true;

        } else {

            return false;

        }
    }

    createReimbursement = async (reimbursement) => {
        this.setLoading('reimbursements');

        const res = await this.post('reimbursements', { 
            params: { reimbursement },
            errorType: 'reimbursements',
        });

        if (!res.error) {

            window.location.href = '/reimbursement';

            return true;

        } else {

            return false;

        }
    }


    getReimbursements = async () => {
        this.setLoading('reimbursements');

        const res = await this.get('reimbursements', {
            errorType: 'reimbursements',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_reimbursements',
                ...res,
            });

            return true;

        } else {

            return false;

        }
    }

    getRates = async () => {
        this.setLoading('rates');

        const res = await this.get('reimbursements/rates', {
            errorType: 'rates',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_rates',
                ...res,
            });

            return true;

        } else {

            return false;

        }
    }

    rejectReimbursement = async (id, feedback) => {
        this.setLoading('update_reimbursement');

        const res = await this.post(`reimbursements/${id}/reject`, {
            params: { feedback },
            errorType: 'update_reimbursement',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_reimbursements',
                ...res,
            });

            store.reduce({
                type: 'success',
                successType: 'update_reimbursement',
                success: 'Reimbursement has been rejected.'
            });

            return true;

        } else {

            return false;

        }
    }

    acceptReimbursement = async (id) => {
            this.setLoading('update_reimbursement');

            const res = await this.post(`reimbursements/${id}/accept`, {
                errorType: 'update_reimbursement',
            });

            if (!res.error) {

                store.reduce({
                    type: 'set_reimbursements',
                    ...res,
                });

                store.reduce({
                    type: 'success',
                    successType: 'update_reimbursement',
                    success: 'Reimbursement has been accepted!'
                });

                return true;

            } else {

                return false;

            }
        }


    setError = (errorType, error) => {
        store.reduce({
            type: 'error',
            errorType,
            error,
        });
    }

    setLoading = (loadingType) => (
        store.reduce({
            type: 'loading',
            loadingType,
        })
    )
}

export let api = new Api();
window.api = api;
