export default function(elements=[], callback) {

	document.onscroll = () => {
		elements.forEach(e => {
			const { y } = e.getBoundingClientRect();
			if (y < 50 && y > -50) {
				callback(e);
			};
		});
	};

};