import React, { useRef, useContext} from 'react';
import Context from '../context';
import { Submit } from '../components';

const FileUpload = ({ onUpload, type, copy="Upload File" }) => {
	const fileInput = useRef();
	const { state } = useContext(Context);

	return(
		<div id="file-upload">
			<div onClick={() => fileInput.current.click()} >
                <Submit
                    copy={copy}
                    loading={state.loading[type]}
                />
            </div>
            <input
                id="census-upload"
                type="file" 
                onChange={({ target }) => onUpload(target.files[0])}
                style={{ display: 'none' }}
                ref={fileInput}
            />
		</div>
	);
};

export default FileUpload;