import React from 'react';

const _404_ = () => (
    <div>
        <div className="row">
            <div className="card card-info card-wrapper col-12">
                <i className="fa-solid fa-narwhal text text-huge"/>
                <div className="text text-big">
                    Uh-oh, this page doesn't exist...
                </div>
            </div>

        </div>
    </div>
);

export default _404_;