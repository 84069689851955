import React, { useContext, useEffect } from 'react';
import { 
	Loader,
	ErrorBox,
	CompanyAdminOnboard, 
	AgencyAdminOnboard 
} from '../components';
import Context from '../context';

const Onboard = () => {
	const { state, api } = useContext(Context);
	
	useEffect(() => {
        api.getUser();
    }, []);
		
	const loading = state.loading.user;
    const error = state.errors.user;

	if (loading) return <Loader />;
    if (error) return <ErrorBox error={error} />;

	if (state.user.is_partner_admin) return <AgencyAdminOnboard />;
	if (state.user.is_company_admin) return <CompanyAdminOnboard />;

	// TODO: handle non onboarding roles (???)
};

export default Onboard;