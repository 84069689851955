import React, { useContext, useEffect, useState, useRef } from 'react';
import Context from '../context';
import { 
    Loader, 
    ErrorBox,
    SuccessBox,
    Form,
    Classes, 
    UpdateDesignFees,
    TaskCard,
} from '../components';
import { allTaskCardsSelected } from '../utils';

const Design = ({ match }) => {
    const { api, state } = useContext(Context);

    useEffect(() => {
        api.getDesign(match.params.id);
    }, []);

    const loading = state.loading.designs;
    const error = state.errors.designs;
    const success = state.success.designs;

    if (loading) return <Loader />;
    if (error) return <ErrorBox error={error} />;

    const { id, name, prospect_email } = state.design;

    function onSubmit(params) {
        if (allTaskCardsSelected()) {
            params.design_id = state.design.id;
            api.inviteCompanyAdmin(params);
        } else {
            // render error ?
        }
    };

    return(
        <div id="prospects">
            <div id="page-header">
                <div className="page-title">Review <span style={{color:'#e1795a'}}>{name}</span></div>
                <a
                    className="btn-soft"
                    href={`/prospects/designs/${state.design.id}`}
                >
                    <i className="fa-solid fa-caret-left"></i>
                    &nbsp;
                    {"Back"}
                </a>
            </div>
            <div className="section-title">Please review the classes and fees and check the boxes before sending to the employer.</div>
            <SuccessBox success={success} />
            <Classes 
                row="12" 
                add={false} 
                taskCards={true}
            />
            <div id="section-header">
                <div className="section-title">
                    <i className="fa-solid fa-badge-dollar"/>
                    &nbsp;
                    Monthly Fees
                </div>
            </div>
            <UpdateDesignFees>
                {({ platformFee, subscriptionFee, updatePlatformFee, updateSubscriptionFee }) => (
                    <div>
                        <TaskCard>
                            <div className="card">
                                <div className="card-wrapper">
                                    <div className="text">
                                        <div className="text-title">Design & Support Fee</div>
                                    </div>
                                    <div className="text-right">
                                        <div className="input-primary" style={{display: 'flex', maxWidth: '120px',alignItems: 'center'}}>
                                            <span style={{marginRight: '3px'}}>$</span>
                                            <input
                                                value={platformFee} 
                                                onChange={updatePlatformFee} 
                                                type="number" 
                                            />
                                        </div>
                                        <div>company /mo.</div>
                                    </div>
                                </div>
                            </div>
                        </TaskCard>
                        <TaskCard>
                            <div className="card">
                                <div className="card-wrapper">
                                    <div className="text"> 
                                        <div className="text-title" >Subscription Fee</div>
                                    </div>
                                    <div className="text-right">
                                        <div className="input-primary" style={{display: 'flex', maxWidth: '120px', alignItems: 'center', alignSelf: 'right'}}>
                                            <span style={{marginRight: '3px'}}>$</span>
                                            <input 
                                                value={subscriptionFee} 
                                                onChange={updateSubscriptionFee} 
                                                type="number" 
                                            />
                                        </div>
                                        <div>user /mo.</div>
                                    </div>
                                </div>
                            </div>
                        </TaskCard>
                    </div>
                )}
            </UpdateDesignFees>
            <div id="section-header">
                <div className="section-title">
                    <i className="fa-solid fa-building"/>
                    &nbsp;
                    Company Info
                </div>
            </div>
            <div >
                <Form
                    onSubmit={onSubmit}
                    submitCopy="Invite Company Admin"
                    type="company_admins"
                    col="6"
                    inputs={[
                        {
                            type: 'email',
                            label: 'Company Admin Email',
                            icon: 'fa-solid fa-user-crown',
                            key: 'email',
                            defaultValue: state.design.prospect_email,
                        },
                        {
                            type: 'date',
                            label: 'Plan Effective Date',
                            placeholder: '01-01-2021',
                            icon: 'fa-solid fa-calendar-day',
                            key: 'plan_effective_date',
                        }
                    ]}
                />
            </div>
        </div>
    );
}

export default Design;