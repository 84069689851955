import React from 'react';
import designiconSrc from '../assets/design_icon.png';
import rostericonSrc from '../assets/roster_icon.png';
import XLSX from 'xlsx';

export {default as observer} from './observer.js';
export {default as onWindowExit} from './on_window_exit.js';
export {default as onWindowReEnter} from './on_window_reenter.js';


export function parseQuery(queryString) {
    if (queryString) {
        const paramStrings = queryString.substring(1).split('&');
        return paramStrings.reduce((params, paramString) => {
            const [key, value] = paramString.split('=');
            params[key] = value;
            return params;
        }, {});
    } else {
        return {};
    }
}

export function capitalize(string="") {
    if (Array.isArray(string)) {
        return string.map(s => capitalize(s));
    } else {
        const split = string.split('');
        split[0] = split[0].toUpperCase();
        return split.join('');
    };
}

export function parseXLSX(file, onLoad) {
    const reader = new FileReader();
    reader.onload = function({ target }) {
        const workbook = XLSX.read(target.result, {type: 'array', cellText: false, cellDates: true});
        const sheet = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 1, raw: false, dateNF: 'yyyy-mm-dd' });
        onLoad(sheet);
    };
    reader.readAsArrayBuffer(file);
}

export function toUSD(int) {
  if (int) {
    return parseInt(int).toLocaleString(undefined, { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0});
  } else {
    return "$0";
  };
}

export const Check = () => (
    <svg width="12" height="12" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M8.25 0.75C8.04 0.75 7.8525 0.8325 7.7175 0.9675L3 5.6925L1.2825 3.9675C1.1475 3.8325 0.96 3.75 0.75 3.75C0.3375 3.75 0 4.0875 0 4.5C0 4.71 0.0825 4.8975 0.2175 5.0325L2.4675 7.2825C2.6025 7.4175 2.79 7.5 3 7.5C3.21 7.5 3.3975 7.4175 3.5325 7.2825L8.7825 2.0325C8.9175 1.8975 9 1.71 9 1.5C9 1.0875 8.6625 0.75 8.25 0.75Z" fill="#43A047"/>
    </svg>
);

export const X = () => (
    <svg width="12" height="12" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M5.5575 4.5L8.025 2.0325C8.1675 1.8975 8.25 1.71 8.25 1.5C8.25 1.0875 7.9125 0.75 7.5 0.75C7.29 0.75 7.1025 0.8325 6.9675 0.9675L4.5 3.4425L2.0325 0.9675C1.8975 0.8325 1.71 0.75 1.5 0.75C1.0875 0.75 0.75 1.0875 0.75 1.5C0.75 1.71 0.8325 1.8975 0.9675 2.0325L3.4425 4.5L0.975 6.9675C0.8325 7.1025 0.75 7.29 0.75 7.5C0.75 7.9125 1.0875 8.25 1.5 8.25C1.71 8.25 1.8975 8.1675 2.0325 8.0325L4.5 5.5575L6.9675 8.025C7.1025 8.1675 7.29 8.25 7.5 8.25C7.9125 8.25 8.25 7.9125 8.25 7.5C8.25 7.29 8.1675 7.1025 8.0325 6.9675L5.5575 4.5Z" fill="#FCCBC8"/>
    </svg>
)



export const GreenArrowUp = () => (
    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M11.76 6.37L11.77 6.36L6.77 0.36L6.76 0.37C6.57 0.15 6.31 0 6 0C5.69 0 5.43 0.15 5.24 0.37L5.23 0.36L0.23 6.36L0.24 6.37C0.0999998 6.54 0 6.76 0 7C0 7.55 0.45 8 1 8H11C11.55 8 12 7.55 12 7C12 6.76 11.9 6.54 11.76 6.37Z" fill="#43A047"/>
    </svg>
)

export const PlusSign = () => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M13 6H8V1C8 0.45 7.55 0 7 0C6.45 0 6 0.45 6 1V6H1C0.45 6 0 6.45 0 7C0 7.55 0.45 8 1 8H6V13C6 13.55 6.45 14 7 14C7.55 14 8 13.55 8 13V8H13C13.55 8 14 7.55 14 7C14 6.45 13.55 6 13 6Z" fill="white"/>
    </svg>

)

export const Art = () => (
    <div className="text-center" >
        <img src={designiconSrc} alt="art" width="40px" />
    </div>
)

export const Ppl = () => (
    <div className="text-center" >
        <img src={rostericonSrc} alt="ppl" width="40px" />
    </div>
)

export const ArrowDown = () => (
    <svg width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M7 0C6.86 0 6.735 0.055 6.645 0.145L4 2.795L1.355 0.145C1.265 0.055 1.14 0 1 0C0.725 0 0.5 0.225 0.5 0.5C0.5 0.64 0.555 0.765 0.645 0.855L3.645 3.855C3.735 3.945 3.86 4 4 4C4.14 4 4.265 3.945 4.355 3.855L7.355 0.855C7.445 0.765 7.5 0.64 7.5 0.5C7.5 0.225 7.275 0 7 0Z" fill="#758491"/>
    </svg>
)

export const ArrowUp = () => (
    <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M1.03615 4.13859C1.17612 4.13614 1.30015 4.07897 1.38856 3.98741L3.98691 1.29166L6.67776 3.89509C6.76931 3.98351 6.89525 4.03632 7.03523 4.03387C7.31019 4.02907 7.53123 3.80018 7.52643 3.52522C7.52399 3.38524 7.46681 3.26122 7.37526 3.17281L4.32336 0.225621C4.2318 0.137206 4.10586 0.0843957 3.96588 0.0868391C3.8259 0.0892824 3.70188 0.146456 3.61346 0.238013L0.666279 3.28991C0.577863 3.38147 0.525053 3.50741 0.527496 3.64739C0.532295 3.92235 0.761188 4.14339 1.03615 4.13859Z" fill="#758491"/>
    </svg>

)


export const SmallArrowUp = () => (
    <svg width="9" height="6" viewBox="0 0 6 4" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M0.599804 3.77319C0.711804 3.77319 0.811804 3.72919 0.883804 3.65719L2.9998 1.53719L5.1158 3.65719C5.1878 3.72919 5.2878 3.77319 5.3998 3.77319C5.6198 3.77319 5.7998 3.59319 5.7998 3.37319C5.7998 3.26119 5.7558 3.16119 5.6838 3.08919L3.2838 0.689193C3.2118 0.617193 3.1118 0.573194 2.9998 0.573194C2.8878 0.573194 2.7878 0.617193 2.7158 0.689193L0.315804 3.08919C0.243804 3.16119 0.199804 3.26119 0.199804 3.37319C0.199804 3.59319 0.379804 3.77319 0.599804 3.77319Z" fill="#E1795A"/>
    </svg>

)

export const SmallArrowDown = () => (
    <svg width="9" height="6" viewBox="0 0 6 4" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M5.4002 0.572998C5.2882 0.572998 5.1882 0.616998 5.1162 0.688998L3.0002 2.809L0.884195 0.688998C0.812195 0.616998 0.712195 0.572998 0.600195 0.572998C0.380195 0.572998 0.200195 0.752998 0.200195 0.972998C0.200195 1.085 0.244195 1.185 0.316195 1.257L2.7162 3.657C2.7882 3.729 2.8882 3.773 3.0002 3.773C3.1122 3.773 3.2122 3.729 3.2842 3.657L5.6842 1.257C5.7562 1.185 5.8002 1.085 5.8002 0.972998C5.8002 0.752998 5.6202 0.572998 5.4002 0.572998Z" fill="#E1795A"/>
    </svg>

)


export const states = [
    'AK', 'AL', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 
    'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 
    'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 
    'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 
    'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
];

export function allTaskCardsSelected() {
    return Array.from(document.querySelectorAll('[data-task-card="1"]')).every(input => {
        return input.checked;
    })
}

export function download(url, name) {
    const a = document.createElement('a');
    a.href = url;
    a.download = name;
    document.body.appendChild(a);
    a.click();    
    a.remove();
}

export function toBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

