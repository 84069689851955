export default (function () {

  function callbackWrapper(entries, callback, intersectionRatio) {
    entries.forEach(entry => {
      if (entry.intersectionRatio === intersectionRatio) {
        callback(entry)
      }
    });
  };

  return function (callback, intersectionRatio=1) {
    const options = {
      root: null,
      rootMargin: '25px',
      threshold: 1.0,
    };

    return new IntersectionObserver(function(entries) {
      callbackWrapper(entries, callback, intersectionRatio)
    }, options);
  };

})();