import React from 'react';
import { GreenArrowUp } from '../utils';
import { InfoTooltip } from '../components';

const DashboardCard = ({ value, label, textColor, textWeight, diff, href, icon, info }) => {

    function go() {
        if (href) window.location.href = href;
    }

    return(
        <div className="card" onClick={go} >
            <div className="card-wrapper">
                <div className="text text-label" style={{color: textColor}}>
                    {label}
                </div>
                {
                    icon

                ?   <div className="text-right" >
                        <i className={icon} />
                    </div>

                    :   null
                }
            </div>
            <div className="text text-value" style={{color: textColor, fontWeight: textWeight}}>
                {value || "0"}
            </div>
            <div className="card-wrapper">
                {
                    diff

                    ?  <div className="text text-diff">
                            <GreenArrowUp />
                            <span>&nbsp;&nbsp;{diff} in the last 90 days</span>
                        </div>

                    :   <div className="text text-diff">&nbsp;</div>

                }
            </div>
            <div className="card-wrapper">
                {
                    info

                    ?   <div className="text-right hover">
                            <InfoTooltip copy={info} />
                        </div>

                    :   null
                }
            </div>
        </div>
    )
};

export default DashboardCard;