import React, { useState } from 'react';

const TaskCard = ({ _ref_, children, defaultChecked=false }) => {
    const [checked, setChecked] = useState(defaultChecked);

    return(
        <div className="task-card">
            <div className="card-header-wrapper" style={{width: '100%'}} >
                <div className="text-left" style={{marginRight: '10px'}}>
                    {
                        checked


                        ?   <i 
                                className="fa-solid fa-check-square edm-matched-active icon-green icon" style={{fontSize: 'larger'}}
                                onClick={() => setChecked(false)}
                            />                                
                        
                        :   <i
                                className="fa-solid fa-square edm-matched-inactive icon-yellow icon"
                                onClick={() => setChecked(true)}
                            />
                    }
                </div>
                <div style={{flexGrow: '1'}}>
                    {children}
                    <input
                        type="checkbox"
                        checked={checked}
                        ref={_ref_}
                        style={{display: 'none'}}
                        data-task-card={1}
                        readOnly={true}
                    />
                </div>
            </div>
        </div>
    );
};

export default TaskCard;