import React, { useState }  from 'react';

const ListInput = ({ inputs=[], kie, label, refs, refInputs, buildInputGroup }) => {
    const [n, setN] = useState(1);

    refs[kie] = [];

    for (let i = 0; i < 10; i++) {
        refs[kie].push(refInputs(inputs));
    };

    const res = []

    for (let i = 0; i < n; i++) {

        const nRefs = refs[kie][i];

        res.push((
            <div key={i} className="row" >
                {inputs.map((input, i) =>
                    buildInputGroup(input, i, nRefs[input.key])
                )}
            </div>
        ));
    };
    
    return(
        <div className="col-12 input-primary">
            <div className="flex-between">
                <label className="flex-grow">{label}</label>
                <i className="fa-solid fa-plus-circle" onClick={() => setN(prev => prev + 1)} />
                <i className="fa-solid fa-minus-circle" onClick={() => setN(prev => prev - 1)} />
            </div>
            {res}  
        </div>
    );
};

export default ListInput;