import React, { useContext } from 'react';
import { DesignCard, ErrorBox } from '../components';
import { Art } from '../utils';
import Context from '../context';

const NoDesignsMsg = () => (
    <div className="card card-prompt nohover">
        <div className="card-header-wrapper">
            <div className="text text-icon">
                <i className="fa-solid fa-whistle icon-navy icon"></i>
            </div>
            <div className="text text-description">It looks like you haven't created a plan design, yet. 
            To get started, click the <b style={{ color: '#e1795a'}} >orange button</b> above.
            </div>
        </div>
    </div>
);

const Designs = () => {
    const { api, state } = useContext(Context);

    async function newDesign() {
        api.createDesign(state.prospect.id);
    }

    const error = state.errors.designs;
    const designs = state.prospect.designs;

    return(
        <div id="designs">
            <div>
                <ErrorBox error={error} />
            </div>
            <div id="section-header">
                <div className="section-title">
                    <i className="fa-solid fa-palette">
                    </i>
                    &nbsp;
                    Plan Designs</div>
                <a
                    className="btn-orange"
                    onClick={newDesign}
                >
                    <i className="fa-solid fa-plus-circle">
                    </i>
                    &nbsp;
                    Design
                </a>
            </div>
            {
                designs && designs.length > 0

                ?   designs.map((design, i) =>
                        <DesignCard 
                            key={i} 
                            {...design} 
                        />
                    )
                :   <NoDesignsMsg />
            }
        </div>
    )
};

export default Designs;