import React, { useContext } from 'react';
import { Table } from '../components';
import Context from '../context';

const Roster = ({ employees=[], prospectId, children, schema }) => {
    
    const EmptyRosterMsg = () => (
        <div className="card card-prompt nohover">
            <div className="card-header-wrapper">
                <div className="text text-icon">
                    <i className="fa-solid fa-whistle icon-light-navy icon"></i>
                </div>
                {
                    children

                    ?   children

                    :   <div className="text text-description">There aren't any employees on the roster. To add employees, click the <span style={{ color: '#e1795a'}} >orange button</span> above.</div>   
                        
                }
            </div>
        </div>
    );

    const { state, api } = useContext(Context);

    return(
        <div id="roster">
            <div id="section-header">
                <div className="section-title">
                    <i className="fa-solid fa-user-astronaut">
                    </i>
                    &nbsp;
                    Employee Roster</div>
                <a
                    className="btn-orange"
                    href={`/prospects/${prospectId}/employees/new`}
                >
                    <i className="fa-solid fa-plus-circle">
                    </i>
                    &nbsp;
                    Employee
                </a>
            </div>
            {employees.length > 0
                ?   <Table
                        schema={schema}
                        data={employees}
                        onLinkClick={id => window.location.href = `/employees/${id}`}
                        onDestroy={id => api.destroyEmployee(id)}
                    />

                :   <EmptyRosterMsg />
            }
        </div>
    )
};

export default Roster;