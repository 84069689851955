import React, { useContext } from 'react';
import Context from '../context';
import { Form, ErrorBox, Submit, FileUpload } from '../components';
import { parseXLSX } from '../utils';
import { employeeParams } from '../params';

const NewEmployee = ({ match }) => {
    const { api, state } = useContext(Context);

    async function onSubmit(params) {
        params.married = params.married === 'Yes';
        api.createEmployee(match.params.prospect_id, params);
    }

    async function consume(file) {
        parseXLSX(file, (sheet) => {
            const employees = sheet.slice(1, sheet.length)
                .filter(row => row.length && row[0] && row[1])
                .map((e) => { 
                    return {
                        first_name: e[0],
                        last_name: e[1],
                        email: e[2],
                        coverage_level: e[3],
                        annual_income: e[4],
                        state: e[5],
                        county: e[6],
                        zip: e[7],
                        birthdate: new Date(e[8]),
                        spouse_birthdate: new Date(e[9]),
                        dependents: e[10],
                        married: e[11],
                        employee_type: e[12],
                        pay_type: e[13],
                    }
                }
            );
            api.createEmployees(match.params.prospect_id, employees);
        });
    }



    return(
        <div id="prospects">
            <div id="page-header">
                <div className="page-title">Add Employee</div>
                <a
                    className="btn-soft"
                    onClick={() => window.location = document.referrer}
                >
                    <i className="fa-solid fa-caret-left"></i>
                    &nbsp;
                    {"Back"}
                </a>
            </div>
            <ErrorBox error={state.errors.bulk_employees} />
            <div className="card card-census" style={{marginBottom: '50px'}}>
                <div className="card-wrapper">
                    <div className="card-header-wrapper">
                        <div className="text text-icon nohover">
                            <i className="fa-solid fa-file-spreadsheet icon-navy icon"></i>
                        </div>
                        <div>
                            <div className="text text-title">Upload Census</div>
                            <div className="text text-description">Add multiple employees with a simple upload!</div>
                        </div>
                    </div>
                    <div className="card-header-wrapper hover">
                        <a 
                            id="btn-download"
                            href="/api/v1/download_template"
                            download={true}
                        >
                            <i className="fa-solid fa-folder-download icon-navy icon"></i>
                            Template
                        </a>
                        <FileUpload onUpload={consume} type="bulk_employees" />
                    </div>
                </div>
            </div>
            <Form
                onSubmit={onSubmit}
                submitCopy="Add Employee"
                type="employees"
                inputs={employeeParams()}
            />
            {/*onDragLeave={this.onDragLeave}
                onDragOver={this.onDragOver}
                onDrop={this.onDrop}*/}
        </div>
    );
}

export default NewEmployee;
