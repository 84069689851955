import React from 'react';
import BtnSpinner from './btn_spinner.js';

const Submit = ({ copy, loading, disabled, style }) => {
    if (loading) return <BtnSpinner style={style} />;

    return(
        <input
            className="btn-navy"
            type="submit"
            value={copy}
            style={style}
            disabled={disabled}
        />
    );
}

export default Submit;