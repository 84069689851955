import React, { useContext, useEffect } from 'react';
import { Form, Loader, ErrorBox } from '../components';
import { employeeParams } from '../params';
import Context from '../context';

const EditEmployee = ({ match }) => {
    const { state, api } = useContext(Context);

    useEffect(() => {
        api.getEmployee(match.params.id);
    }, []);

    function onSubmit(params) {
        api.updateEmployee(state.employee.id, params);
    }


    const loading = state.loading.employees;
    const error = state.errors.employees;

    if (loading) return <Loader />;
    if (error) return <ErrorBox error={error} />;

    return(
        <div style={{marginTop: 'auto'}} >
            <div id="page-header">
                <div className="page-title">Edit Employee</div>
                <a
                    className="btn-soft"
                    onClick={() => window.location = document.referrer}
                >   <i className="fa-solid fa-caret-left"></i>
                    &nbsp;
                    {"Back"}
                </a>
            </div>
            <Form
                type="update_employee"
                onSubmit={onSubmit}
                submitCopy="Update Employee"
                inputs={employeeParams(state.employee)}
            />
        </div>
    )
};

export default EditEmployee;