import React, { useContext, useEffect } from 'react';
import Context from '../context';
import { AgentTable, Loader, ErrorBox } from '../components';

const tableSchema = {
    name: {
        bold: true,
        textAlign: 'left',
    },
    email: {
        textAlign: 'left',
    },
    agency: {
        textAlign: 'center',
    },
    prospects: {
        textAlign: 'right',
    },
};

const Agents = () => {
    const { api, state } = useContext(Context);
    
    useEffect(() => { api.getAgents() }, []);

    const loading = state.loading.agents;
    const error = state.errors.agents;

    if (loading) return <Loader />;
    if (error) return <ErrorBox error={error} />;

    return(
        <div id="prospects">
            <div id="page-header">
                <div className="page-title">Brokers</div>
            </div>
            <AgentTable 
                agents={state.agents} 
                link={true} 
                fetchMore={api.getAgents} 
            />
        </div>
    );
}

export default Agents;