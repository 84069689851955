import React, { useContext } from 'react';
import { PaymentMethodCard } from '../components';
import Context from '../context';

const Payouts = () => {
	const { api, state } = useContext(Context);

	return(
		<PaymentMethodCard 
			token={true}
			onUpdate={(id) => api.attachExternalAccount(state.account.id, id)}
		/>
	);
};

export default Payouts;