import React, { useState, useContext } from 'react';
import Context from '../context';

const UpdateDesignFees = ({ children }) => {
    const { api, state } = useContext(Context);
    const [subscriptionFee, setSubscriptionFee] = useState(state.design.subscription_fee);
    const [platformFee, setPlatformFee] = useState(state.design.platform_fee);

    function updatePlatformFee({ target }) {
        setPlatformFee(target.value);
        api.updatePlatformFee(state.design.id, target.value);
    }

    function updateSubscriptionFee({ target }) {
        if (target.value >= 30 && target.value <= 60) {
            setSubscriptionFee(target.value);
            api.updateSubscriptionFee(state.design.id, target.value);
        }
    }

    return children({
        platformFee, subscriptionFee, 
        updatePlatformFee, updateSubscriptionFee,
    });

};

export default UpdateDesignFees;

