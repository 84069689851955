import React, { Component, createContext } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Login, SignUp, CompanyAdminSignUp, EmployeeSignUp, ResetPassword, ChangePassword, Onboard } from "../src/pages";
import Routes from "../src/routes";
import { store } from '../src/store';
import { api } from '../src/api';
import Context from '../src/context';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import 'react-tippy/dist/tippy.css';

const stripePromise = loadStripe(window.stripe_public_key);

class Root extends Component {
  constructor(props) {
      super(props);
      this.store = store;
      this.state = this.store.state;
      this.store.setStateHandler(this.setState.bind(this));
  }

  // bootIntercom(user) {
  //   if (user.id && location.hostname != 'localhost')
  //     window.Intercom('boot', {
  //       app_id: 'ui27tgvx',
  //       email: user.email,
  //       user_id: user.id,
  //       created_at: user.created_at,
  //       user_hash: user.intercom_hmac,
  //     });
  // }

  componentDidMount() {
    const success = localStorage.getItem('success');
    if (success) {
      localStorage.removeItem('success');
      store.reduce({
        type: 'success',
        successType: 'standard',
        success: success,
      });
    }
  }

  render() {
    return (
      <Context.Provider value={{ api, state: this.state }} >
        <Elements stripe={stripePromise}>
          <Router>
            <Switch>
              {   
                  /* onboarding flow */

                  !this.state.account.has_onboarded 

                  ?   <Route component={Onboard} /> 

                  :   null
              }
              <Route exact path="/login" component={Login}/>
              <Route exact path="/sign_up" component={SignUp}/>
              <Route exact path="/partner/sign_up/:token" component={SignUp}/>
              <Route exact path="/company_admins/sign_up/:token" component={CompanyAdminSignUp}/>
              <Route exact path="/employees/sign_up/:token" component={EmployeeSignUp}/>
              <Route exact path="/reset_password" component={ResetPassword}/>
              <Route exact path="/change_password/:token" component={ChangePassword}/>
              <Route component={Routes}/>
            </Switch>
          </Router>
          <footer>
            <div id="footer-top"><b>401k</b>health is designed, built, & backed by Remodel Health.</div>
            <div id="footer-bottom">©2023 Remodel Health. All rights reserved.</div>
          </footer>
        </Elements>
      </Context.Provider>
    );
  }
}

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <Root />,
    document.body.appendChild(document.createElement('div')),
  )
});
